import { Menu } from '../../../../components/Clientes/Menu/Menu';
import { PedidoCard } from '../../../../components/Clientes/PedidoCard/PedidoCard';
import Carrito from '../../../../assets/carrito.png';
import { useState, useEffect } from 'react';
import './MisPedidos.css';

export const MisPedidos = () => {
  const [data, setData] = useState(null);
  function getPedidos() {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portPedidos.php?id_cliente=${userId}`)
      .then((response) => response.json())
      .then((response) => {
        setData(response.data);
      });
  }
  function formatFecha(fechaString) {
    // Parsea la cadena de fecha en un objeto Date
    const fecha = new Date(fechaString);

    // Establece los segundos en 0
    fecha.setSeconds(0);

    // Formatea la fecha en el formato deseado
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0');
    const hours = String(fecha.getHours()).padStart(2, '0');
    const minutes = String(fecha.getMinutes()).padStart(2, '0');

    // Crea una cadena de fecha en el formato deseado
    const fechaSinSegundos = `${year}-${month}-${day} ${hours}:${minutes}`;

    return fechaSinSegundos;
  }

  useEffect(() => {
    getPedidos();
  }, []);
  return (
    <div>
      <Menu ruta={'pedidos'} />
      <div className="container-pedidos">
        {data &&
          data.map((dato) => (
            <PedidoCard
              fecha={formatFecha(dato.FECHA['date'])}
              importe={dato.IMPORTE}
              estado={dato.ESTADO}
              numPedido={dato.ID}
              key={dato.ID}
            />
          ))}
        {!data && (
          <img
            src={Carrito}
            alt=""
            style={{ with: '220px%', height: '200px' }}
          />
        )}
      </div>
    </div>
  );
};
