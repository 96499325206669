import { useState, useEffect, forwardRef, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Menu } from '../../../../components/Clientes/Menu/Menu';
import Swal from 'sweetalert2';
// import FotoProducto1 from "../../../../assets/frutas/limones.png";
// import FotoProducto2 from "../../../../assets/frutas/sandia.jpg";
// import FotoProducto3 from "../../../../assets/oferta-frutas.png";
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import './Producto.css';
import { DataContext } from '../../../../context/DataContext';

export const Producto = () => {
  const { id, cantidadCarrito } = useParams();
  const navigate = useNavigate();
  const [cantidad, setCantidad] = useState(
    cantidadCarrito ? cantidadCarrito : 1
  );
  const [datosProducto, setDatosProducto] = useState([]);
  const [imagen, setImagen] = useState(null);
  // eslint-disable-next-line
  const [defaultImg, setDefaultImg] = useState(null);

  const { counterCarrito } = useContext(DataContext);
  // eslint-disable-next-line
  const [cartCounter, setCartCounter] = counterCarrito;

  function getDataProduct() {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(
      `${process.env.REACT_APP_API}/portArticuloDatos.php?id=${id}&idCliente=${userId}`
    )
      .then((res) => res.json())
      .then((json) => {
        setDatosProducto(json.data[0]);
        setImagen(json.data[0].img);
        setDefaultImg(json.data[0].img);
      });
  }

  function reducirCantidad() {
    setCantidad(cantidad > 1 ? cantidad - 1 : null);
  }

  function aumentarCantidad() {
    setCantidad(parseInt(cantidad) + 1);
  }
  // eslint-disable-next-line
  // const handleImage = (event) => {
  //   let file = event.target.getAttribute("src");
  //   setImagen(file);
  // };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [open, setOpen] = useState(false);
  function agregarCarrito() {
    let user = window.localStorage.getItem('user');
    user = JSON.parse(user);
    const dataForm = {};
    const { ID, DISPONIBILIDAD_INICIAL } = datosProducto;
    if (cantidad > DISPONIBILIDAD_INICIAL) {
      Swal.fire({
        text: 'Pendiente de confirmar disponibilidad, contacte con Frutas Cerdan. Tel: 639169335 .',
        type: 'warning',
        icon: 'warning',

        showConfirmButton: true,
      });
    }
    dataForm['id_producto'] = ID;
    dataForm['cantidad'] = cantidad;
    dataForm['id_cliente'] = user[0].ID;
    fetch(
      `${process.env.REACT_APP_API}/portAddArticuloPedido.php?id_producto=${dataForm['id_producto']}&cantidad=${dataForm['cantidad']}&id_cliente=${dataForm['id_cliente']}`
    );

    setOpen(true);
    if (cantidadCarrito) {
      navigate(-1);
    }
  }
  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    getDataProduct();
    // eslint-disable-next-line
  }, [id]);

  function getCounterCart() {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portCarrito.php?id_cliente=${userId}`)
      .then((response) => response.json())
      .then((response) => {
        setCartCounter(response.data?.length);
        if (response.data === null) {
          localStorage.removeItem('id_pedido');
        }
      });
  }

  useEffect(
    () => {
      // eslint-disable-next-line
      getCounterCart();
    }, // eslint-disable-next-line
    [open]
  );

  return (
    <div>
      <Menu />
      <div className="container-product">
        <div className="image-product-gallery">
          <img src={imagen && imagen} alt="" />
          {/* <div className="gallery-mini-images">
            <img src={defaultImg} alt="" onClick={handleImage} />
            <img src={FotoProducto1} alt="" onClick={handleImage} />
            <img src={FotoProducto2} alt="" onClick={handleImage} />
            <img src={FotoProducto3} alt="" onClick={handleImage} />
          </div> */}
        </div>

        <div className="content-product">
          <h1>{datosProducto && datosProducto.NOMBRE}</h1>
          {/* <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit,Lorem ipsum
            dolor sit, amet consectetur adipisicing elit.{" "}
          </p> */}

          <h2>
            {datosProducto && datosProducto.PRECIO}€{' '}
            <span
              style={{
                fontSize: '20px',
                color: 'black',
                fontWeight: 'bold',
              }}
            >
              / Kg (Cantidad a pedir: {cantidad} bultos)
            </span>
          </h2>
          <div className="add-cart-content">
            <div className="qty-input">
              <button
                className="qty-count qty-count--minus"
                data-action="minus"
                type="button"
                onClick={reducirCantidad}
              >
                -
              </button>
              <input
                className="product-qty"
                type="number"
                name="product-qty"
                min="1"
                autoFocus
                value={cantidad}
                onChange={(e) => setCantidad(e.target.value)}
              />
              <button
                className="qty-count qty-count--add"
                data-action="add"
                type="button"
                onClick={aumentarCantidad}
              >
                +
              </button>
            </div>
            <div className="button-add-cart">
              <button onClick={agregarCarrito}>Añadir al carrito</button>
            </div>
          </div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={1500} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          Añadido al pedido correctamente.
        </Alert>
      </Snackbar>
    </div>
  );
};
