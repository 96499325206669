import Swal from 'sweetalert2';
import { DataGrid, GridRowModes, GridCellModes } from '@mui/x-data-grid';
import { RiCloseCircleLine } from 'react-icons/ri';
import { AiFillCloseCircle } from 'react-icons/ai';
import { BiHide } from 'react-icons/bi';
import { MdLocalOffer } from 'react-icons/md';
import { MdOutlineError } from 'react-icons/md';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect, useCallback } from 'react';
import { DataContext } from '../../../../context/DataContext';
import { useForm } from 'react-hook-form';
import { Popover, Typography } from '@mui/material';
import CurrencyInput from 'react-currency-input-field';
import Select from 'react-select';

import './DataTable.css';

function DataTable({ datos, columnas, alturaCelda, form, altura, contenedor }) {
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = useState(null);
  // eslint-disable-next-line
  const [openForm, setOpenForm] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [imagenes, setImagenes] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState(null);
  const [proveedoresOpts, setProveedoresOpts] = useState([]);
  const [tarasOpts, setTarasOpts] = useState([]);
  const [calibresOpts, setCalibresOpts] = useState([]);
  const [familiasOpts, setFamiliasOpts] = useState([]);
  const [categoriasOpts, setCategoriasOpts] = useState([]);

  const { estadoArticulos } = useContext(DataContext);
  const { estadoArticuloIdContextData } = useContext(DataContext);
  const { proveedorContextFilter } = useContext(DataContext);

  const [updateArticulos, setUpdateArticulos] = estadoArticulos;
  // eslint-disable-next-line
  const [estadoArticuloIdContext, setEstadoArticuloIdContext] =
    estadoArticuloIdContextData;
  // eslint-disable-next-line
  const [proveedorContext, setProveedorContext] = proveedorContextFilter;

  const navigate = useNavigate();

  const seleccionarImagenPrincipal = (imagen) => {
    // Actualizamos el estado de imagen principal
    console.log(`imagen Principal:  ${imagen}`);
    setImagenPrincipal(imagen);
  };

  const handleRowClick = (params) => {
    setSelectedRow(params.id);
    setSelectedRowData(params.row);
    console.log(selectedRowData);
    setOpenForm(true);
    setUpdateArticulos(updateArticulos === false ? true : false);
    // getSelectedCombo(params.id);
    // getValues("nombre")
    if (params.row) {
      setValue2('nombre', params.row.NOMBRE);
      setValue2('codigo', params.row.CODIGO);
      setValue2('idGrupo', params.row.ID_GRUPO);
      setValue2('refCliente', params.row.REFERENCIA_CLIENTE);
      setValue2('precio', params.row.PRECIO);
      setValue2('precioOrigen', params.row.PRECIO_ORIGEN);
      setValue2('idVariedad', params.row.ID_VARIEDAD);
      setValue2('idProveedor', params.row.ID_PROVEEDOR);
      setValue2('idCalibre', params.row.ID_CALIBRE);
      setValue2('marca', params.row.MARCA);
      setValue2('idFormato', params.row.ID_FORMATO);
      setValue2('idBultos', params.row.ID_BULTOS);
      setValue2('disponibilidadInicial', params.row.DISPONIBILIDAD_INICIAL);
      setValue2('idUnidadesVenta', params.row.ID_UNIDADES_VENTA);
      setValue2('fechaVigenciaTarifa', currentDateTime);
      setValue2('canarias', params.row.CANARIAS);
      setProveedor(params.row.ID_PROVEEDOR);
      setTaras(params.row.ID_FORMATO);
      setCalibre(params.row.ID_CALIBRE);
      setCategoria(params.row.ID_VARIEDAD);
      setFamilia(params.row.ID_GRUPO);

      // Checked imagen princiapl default
      const baseUrl = 'http://api.frutascerdan.com/cerdan_imagenes/';
      setImagenPrincipal(
        params.row.IMAGEN_PRINCIPAL
          ? `${baseUrl}${params.row.IMAGEN_PRINCIPAL}`
          : ''
      );
      console.log(`${baseUrl}${params.row.IMAGEN_PRINCIPAL}`);
    }

    fetch(
      `${process.env.REACT_APP_API}/portArticulosImagenes.php?id=${params.row.ID}`
    )
      .then((data) => data.json())
      .then((data) => setImagenes(data !== null ? data : []));
  };

  function añadirTara(valor) {
    fetch(`${process.env.REACT_APP_API}/addTara.php?tara=${valor}`).then(
      (response) => {
        if (response.ok) {
          getDataCombos();
        }
      }
    );
  }
  function añadirCalibre(valor) {
    fetch(`${process.env.REACT_APP_API}/addCalibre.php?calibre=${valor}`).then(
      (response) => {
        if (response.ok) {
          getDataCombos();
        }
      }
    );
  }
  function añadirFamilia(valor) {
    fetch(`${process.env.REACT_APP_API}/addFamilia.php?familia=${valor}`).then(
      (response) => {
        if (response.ok) {
          getDataCombos();
        }
      }
    );
  }
  function añadirCategoria(valor) {
    fetch(
      `${process.env.REACT_APP_API}/addCategoria.php?categoria=${valor}`
    ).then((response) => {
      if (response.ok) {
        getDataCombos();
      }
    });
  }

  const handleCloseForm = () => {
    setOpenForm(false);
    setImagenes([]);
    setImagenPrincipal(null);
    // document.getElementById("filter-options-2").value = "";
    setRowModesModel({
      ...rowModesModel,
      [selectedRowData.ID]: { mode: GridRowModes.View },
    });
  };

  const handleImagenChange = (event) => {
    const files = event.target.files;

    const imagenesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imagenesArray.push(e.target.result);
        if (imagenesArray.length === files.length) {
          setImagenes((prevImagenes) => [...prevImagenes, ...imagenesArray]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };

  const eliminarImagen = (index) => {
    setImagenes((prevImagenes) => prevImagenes.filter((_, i) => i !== index));
  };

  const handleBlink = () => {
    fetch(
      `${process.env.REACT_APP_API}/portArticuloBlink.php?codigo=${
        selectedRowData.CODIGO
      }&valor=${form && selectedRowData && selectedRowData.BLINK === 0 ? 1 : 0}`
    );
    setUpdateArticulos(updateArticulos === false ? true : false);
  };

  function borrarArticulo() {
    handleCloseForm();
    Swal.fire({
      title: '¿Seguro que quieres borrar este producto?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `${process.env.REACT_APP_API}/portBorrar.php?id=${selectedRowData.ID}&tabla=ARTICULOS`
        )
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              setUpdateArticulos(updateArticulos === false ? true : false);
              document.getElementById('filter-options-2').value = '';
              Swal.fire({
                text: '¡Producto borrado exitosamente!',
                type: 'success',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
            }
          });
      }
    });
  }

  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    setCurrentDateTime(getCurrentDateTime());
  }, []);

  function getCurrentDateTime() {
    const spainTimezoneOffset = 2;
    const currentTime = new Date();
    const adjustedTime = new Date(
      currentTime.getTime() + spainTimezoneOffset * 60 * 60 * 1000
    );

    // Establece la hora a las 13:00 y avanza al día siguiente
    adjustedTime.setHours(15, 0, 0, 0);
    adjustedTime.setDate(adjustedTime.getDate() + 1);

    return adjustedTime.toISOString().slice(0, 16);
  }

  function handleInputChange(e) {
    setCurrentDateTime(e.target.value);
  }

  const [dataCombos, setDataCombos] = useState(null);
  function getDataCombos() {
    fetch(`${process.env.REACT_APP_API}/portDesplegables.php`)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);

        let proveedores = data.data['proveedores'];
        const provOptsMod = proveedores.map((item) => ({
          value: item.id,
          label: item.nombre,
        }));
        setProveedoresOpts(provOptsMod);
        let taras = data.data['formato'];
        const tarasOptsMod = taras.map((item) => ({
          value: item.id,
          label: item.nombre,
        }));
        setTarasOpts(tarasOptsMod);
        let calibresdata = data.data['calibre'];
        const calibresOptsMod = calibresdata.map((item) => ({
          value: item.id,
          label: item.nombre,
        }));
        setCalibresOpts(calibresOptsMod);

        let familiadata = data.data['familias'];
        const familiasOptsMod = familiadata.map((item) => ({
          value: item.id,
          label: item.nombre,
        }));
        setFamiliasOpts(familiasOptsMod);
        let categoriadata = data.data['variedad'];
        const categoriasOptsMod = categoriadata.map((item) => ({
          value: item.id,
          label: item.nombre,
        }));
        setCategoriasOpts(categoriasOptsMod);
      });
  }

  useEffect(() => {
    getDataCombos();
  }, []);

  const [openNewProductForm, setOpenNewProductForm] = useState(false);
  // function openNewProductDialog() {
  //   setOpenNewProductForm(true);
  //   setImagenes([]);
  //   setImagenPrincipal(null);
  // }

  function closeNewProductDialog() {
    setOpenNewProductForm(false);
  }
  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();

  const [proveedor, setProveedor] = useState(null);
  const [taras, setTaras] = useState(null);
  const [familia, setFamilia] = useState(null);
  const [categoria, setCategoria] = useState(null);
  const [calibre, setCalibre] = useState(null);

  async function crearProducto(data) {
    data['blink'] = blinkCrear;
    data['imagenes'] = imagenes;
    data['idProveedor'] = proveedor;
    data['idFormato'] = taras;
    data['idGrupo'] = familia;
    data['idVariedad'] = categoria;
    data['idCalibre'] = calibre;
    if (typeof data.precio == 'string') {
      let precioFormat = data.precio.replace(/[.\s€]/g, '').replace(',', '.');
      data['precio'] = parseFloat(precioFormat);
    }
    if (typeof data.precioOrigen == 'string') {
      let precioFormat2 = data.precioOrigen
        .replace(/[.\s€]/g, '')
        .replace(',', '.');
      data['precioOrigen'] = parseFloat(precioFormat2);
    }

    fetch(`${process.env.REACT_APP_API}/portActArticulo.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          reset();
          setImagenes([]);
          setUpdateArticulos(updateArticulos === false ? true : false);
          closeNewProductDialog();
          Swal.fire({
            text: '¡Producto creado exitosamente!',
            type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          document.getElementById('filter-options').value = '';
          setUpdateArticulos(updateArticulos === false ? true : false);
          handleCloseForm();
          Swal.fire({
            text: '¡Error al crear el producto!',
            type: 'error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }
  const [blinkCrear, setBlinkCrear] = useState(0);
  function handleBlinkCrearPropducto() {
    setBlinkCrear(!blinkCrear ? 1 : 0);
  }

  function handleFilter(e) {
    if (e.target) {
      let filtro = e.target.name;
      if (filtro === 'estado') {
        setEstadoArticuloIdContext(e.target.value);
      }
    } else {
      setProveedorContext(e.value);
    }
  }

  const defaultValues = {
    nombre:
      selectedRowData && selectedRowData.NOMBRE ? selectedRowData.NOMBRE : '',
    // Agrega aquí otros campos con sus valores iniciales
  };

  const {
    handleSubmit: handleSubmit2,
    register: register2,
    setValue: setValue2,

    // formState: { errors: errors2 },
  } = useForm({
    defaultValues,
  });

  function actualizarProducto(data) {
    // Compara solo la parte base64 ignorando el prefijo `data:image/jpeg;base64,` si es un blob
    const esIgualImagen = (img1, img2) => {
      // Verificamos si ambas imágenes son válidas (no null o undefined)
      if (!img1 || !img2) return false;

      // Comprobamos si ambos son blobs (inician con 'data:image')
      if (img1.startsWith('data:image') && img2.startsWith('data:image')) {
        // Comparar blobs ignorando el prefijo y considerando los primeros 500 caracteres
        return img1.slice(0, 500) === img2.slice(0, 500);
      }
      // Si uno de ellos no es un blob, no los consideramos iguales para esta comparación
      return false;
    };

    // Filtramos las imágenes: solo eliminamos blobs que coincidan con la imagen principal
    const nuevasImagenes = imagenes.filter((img) => {
      // Si la imagen es un blob, aplicamos la lógica de comparación
      if (img.startsWith('data:image')) {
        return !esIgualImagen(img, imagenPrincipal);
      }
      // Si es una URL (no es blob), la mantenemos en el array
      return true;
    });

    // Actualizamos las imágenes antes de enviar los datos
    setImagenes(nuevasImagenes);

    if (selectedRowData.ID === null) {
      data['id'] = selectedRowData.ID;
      data['imagenes'] = [];
      data['idProveedor'] = proveedor;
      data['idFormato'] = taras;
      data['idGrupo'] = familia;
      data['idVariedad'] = categoria;
      data['idCalibre'] = calibre;
      data['imagenPrincipal'] = '';
    } else {
      data['id'] = selectedRowData.ID;
      data['imagenes'] = nuevasImagenes;
      data['idProveedor'] = proveedor;
      data['idFormato'] = taras;
      data['idGrupo'] = familia;
      data['idVariedad'] = categoria;
      data['idCalibre'] = calibre;
      data['imagenPrincipal'] = imagenPrincipal;
    }

    if (typeof data.precio == 'string') {
      let precioFormat = data.precio.replace(/[.\s€]/g, '').replace(',', '.');
      data['precio'] = parseFloat(precioFormat);
    }
    if (typeof data.precioOrigen == 'string') {
      let precioFormat2 = data.precioOrigen
        .replace(/[.\s€]/g, '')
        .replace(',', '.');
      data['precioOrigen'] = parseFloat(precioFormat2);
    }
    var price = parseInt(data.precio);
    var ID = data.id;
    console.log(`La imagen destacada es : ${imagenPrincipal}`);

    fetch(`${process.env.REACT_APP_API}/portActArticulo.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          if (price > 0) {
            fetch(
              `${process.env.REACT_APP_API}/portActEstadoArticulo.php?id=${ID}&estado=2`
            );
          }
          reset();
          setImagenPrincipal(null);
          // document.getElementById("filter-options-2").value = "";
          setUpdateArticulos(updateArticulos === false ? true : false);
          handleCloseForm();
          if (selectedRowData.ID === null) {
            Swal.fire({
              text: '¡Producto creado!',
              type: 'success',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          } else {
            Swal.fire({
              text: '¡Producto actualizado!',
              type: 'success',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false,
            });
          }
        } else {
          setUpdateArticulos(updateArticulos === false ? true : false);
          handleCloseForm();
          Swal.fire({
            text: '¡Error al actualizar el producto!',
            type: 'error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }
  // POPOVER
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    let dataDiv = event.currentTarget.getAttribute('data');
    setTextPopover(dataDiv);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [textPopover, setTextPopover] = useState('');
  const styleSelectFilter = {
    control: (base, state) => ({
      ...base,
      background: '#944ce6e7',
      color: 'white',
      border: 'none',
    }),
    menu: (base) => ({
      ...base,
      color: 'white',
      border: 'none',
    }),
    menuList: (base) => ({
      ...base,
      color: 'white',
      background: '#944ce6e7',
      width: '500px',
      border: 'none',
      // "&:hover": {
      //   background: "red"
      // }
    }),
    singleValue: (base) => ({
      ...base,
      color: 'white',
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected ? 'inherit' : '#944ce6e7',
      '&:hover': {
        background: 'blue',
      },
    }),

    placeholder: (base) => {
      return {
        ...base,
        color: '#ffffff',
      };
    },
  };

  function borrarFiltros() {
    document.getElementById('filter-options-2').value = '';
    setEstadoArticuloIdContext(null);
    setProveedorContext(null);
    navigate('/admin/articulos');
    setUpdateArticulos(updateArticulos === false ? true : false);
  }
  const [rowModesModel, setRowModesModel] = useState({});

  function formatearPrecioStringAFloat(precioString) {
    // Verificar si el precioString es una cadena de texto
    if (typeof precioString !== 'string') {
      return precioString; // Otra opción sería devolver NaN o lanzar una excepción según tus necesidades
    }

    // Eliminar el símbolo de la moneda y cualquier espacio en blanco
    let precioLimpio = precioString.replace(/[^\d.,]/g, '');

    // Reemplazar las comas por puntos (para el formato numérico)
    precioLimpio = precioLimpio.replace(',', '.');

    // Convertir a número de punto flotante
    const precioFloat = parseFloat(precioLimpio);

    // Verificar si la conversión fue exitosa
    if (isNaN(precioFloat)) {
      console.error(
        'Error: No se pudo convertir el precioString a un número válido.'
      );
      return null; // Otra opción sería devolver NaN o lanzar una excepción según tus necesidades
    }

    return precioFloat;
  }
  //=======================Prueba Grid Editable===============================

  const [cellModesModel, setCellModesModel] = useState({});

  const handleCellClick = useCallback((params, event) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      event.target.nodeType === 1 &&
      !event.currentTarget.contains(event.target)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  //===============================Fin prueba Grid Editable====================
  function publicarProductos() {
    Swal.fire({
      title: '¿Seguro que quieres publicar los productos?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(`${process.env.REACT_APP_API}/portPublicarTarifa.php`)
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              Swal.fire({
                text: '¡Productos publicados exitosamente!',
                type: 'success',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
              setUpdateArticulos(updateArticulos === false ? true : false);
            } else {
              Swal.fire({
                text: 'Algunos de los productos no tiene precio de venta, nombre, o imagen principal/destacada',
                type: 'error',
                icon: 'error',
                timer: 3500,
                showConfirmButton: false,
              });
            }
          });
      }
    });
  }
  function crearaPartir(e) {
    e.preventDefault();
    selectedRowData['ID'] = null;
    selectedRowData['IMAGEN_PRINCIPAL'] = '';
    selectedRowData['img'] = '';
    setImagenes([]);
    setImagenPrincipal(null);
    document.getElementById('formulario-actualizar').requestSubmit();
  }
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === '/admin/articulos') {
      document.getElementById('filter-options-2').value =
        estadoArticuloIdContext;
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div
      style={
        !contenedor
          ? { height: 400, width: '100%' }
          : { height: contenedor, width: '100%' }
      }
    >
      {form && (
        <div className="flex-center-2">
          {/* <button
            className="buttonAltaCliente h-100"
            onClick={() => {
              setRowModesModel({
                ...rowModesModel,
                [selectedRow]: { mode: GridRowModes.View },
              });
            }}
          >
            Guardar
          </button> */}
          <select name="estado" id="filter-options-2" onChange={handleFilter}>
            <option value="" defaultChecked>
              Todos
            </option>
            <option value="1">Pendiente precio de venta</option>
            <option value="2">Pendiente de pasar a tarifa</option>
            <option value="3">En tarifa</option>
            <option value="9">No disponible</option>
          </select>
          <Select
            options={proveedoresOpts && proveedoresOpts}
            className="inputAncho baseColorStyle "
            name="proveedor"
            onChange={handleFilter}
            placeholder="Proveedor"
            styles={styleSelectFilter}
          />
          <Select
            options={
              dataCombos &&
              dataCombos['familias'].map((client, index) => {
                return {
                  label: client.nombre,
                  value: client.id,
                  key: index,
                };
              })
            }
            className="inputAncho baseColorStyle "
            styles={styleSelectFilter}
            placeholder="Familia"
            onChange={(choice) => navigate(`/admin/articulos/${choice.value}`)}
          />

          <button
            className="buttonAltaCliente h-100"
            style={{ background: '#e64c53e7' }}
            onClick={borrarFiltros}
          >
            Borrar filtros
          </button>
          {estadoArticuloIdContext === '2' && datos && (
            <button
              className="buttonAltaCliente h-100"
              onClick={publicarProductos}
              style={{ background: '#63bf68' }}
            >
              Publicar productos
            </button>
          )}

          {/* <button
            className="buttonAltaCliente h-100"
            onClick={openNewProductDialog}
            style={{ background: '#5be64ce7' }}
          >
            Nuevo producto
          </button> */}
        </div>
      )}
      {datos ? (
        <DataGrid
          rows={datos && datos}
          columns={columnas}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          // rowModesModel={rowModesModel}
          slotProps={{
            cell: {
              onFocus: (event) => {
                event.currentTarget.click();
              },
            },
          }}
          onRowDoubleClick={form && handleRowClick}
          onProcessRowUpdateError={(error) => {
            console.log(error);
          }}
          processRowUpdate={(choice) => {
            // console.log(choice.ID)
            let ID = choice.ID;
            let precio;
            if (typeof precio === 'string') {
              precio = parseInt(choice.PRECIO.replace('€', ''));
            } else {
              precio = choice.PRECIO;
            }

            console.log(choice);
            if (choice.PROVEEDOR !== null) {
              fetch(`${process.env.REACT_APP_API}/portActArticuloGrid.php`, {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(choice),
              })
                .then((data) => data.json())
                .then((data) => {
                  if (data === 'Ok') {
                    if (precio > 0) {
                      fetch(
                        `${process.env.REACT_APP_API}/portActEstadoArticulo.php?id=${ID}&estado=2`
                      );
                    }
                    console.log('actualizado grid');
                    setUpdateArticulos(
                      updateArticulos === false ? true : false
                    );
                  } else {
                    console.log('error actualizado grid');
                    setUpdateArticulos(
                      updateArticulos === false ? true : false
                    );
                  }
                });
              choice['PRECIO'] = formatearPrecioStringAFloat(choice['PRECIO']);
              choice['PRECIO_ORIGEN'] = formatearPrecioStringAFloat(
                choice['PRECIO_ORIGEN']
              );
              console.log(choice);
              return choice;
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Proveedor no seleccionado',
                text: 'Debe seleccionar un proveedor antes de actualizar el producto.',
                confirmButtonText: 'Entendido',
                confirmButtonColor: '#f39c12', // Puedes ajustar el color del botón si deseas
              });
              setUpdateArticulos(updateArticulos === false ? true : false);
              return choice;
            }
          }}
          getRowId={(row) => row.ID}
          style={
            !altura ? { height: '85vh', fontSize: '17px' } : { height: altura }
          }
          rowHeight={alturaCelda}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ID: false,
                REFERENCIA_CLIENTE: false,
                ID_GRUPO: false,
              },
            },
            pagination: { paginationModel: { pageSize: 50 } },
          }}
        />
      ) : (
        <div className="no-data">
          <MdOutlineError style={{ fontSize: '50px' }} />
          <h1>No hay datos.</h1>
        </div>
      )}
      {/* ===================================Ventana de Actualización============================== */}
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              boxShadow: 'none',
            },
          },
        }}
      >
        <div
          className="switch-button"
          aria-owns={open ? 'mouse-over-popover-blink' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          data="Oferta Flash"
        >
          <input
            type="checkbox"
            name="switch-button"
            id="switch-label"
            className="switch-button__checkbox"
            onClick={handleBlink}
            defaultChecked={
              form && selectedRowData && selectedRowData.BLINK === 0
                ? false
                : true
            }
          />

          <label
            htmlFor="switch-label"
            className="switch-button__label"
          ></label>
        </div>

        <BiHide
          className="trash-articulo"
          onClick={borrarArticulo}
          aria-owns={open ? 'mouse-over-popover-blink' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          data="Pasar a 'No disponible'"
        />
        <AiFillCloseCircle
          className="close-dialog-articulo"
          onClick={handleCloseForm}
        />
        <MdLocalOffer
          className="local-offer"
          style={{
            fontSize: '35px',
            position: 'absolute',
            right: '80px',
            top: '10px',
            color: '#702fb9ad',
            cursor: 'pointer',
          }}
          onClick={() =>
            navigate(`/admin/crear-oferta-especial/${selectedRowData.CODIGO}`)
          }
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          data="Oferta Especial"
        />

        <DialogTitle style={{ textAlign: 'center', color: '#370570' }}>
          Actualización de Producto
        </DialogTitle>
        <DialogContent>
          <form
            id="formulario-actualizar"
            onSubmit={handleSubmit2(actualizarProducto)}
          >
            {form && selectedRowData && (
              <div>
                <div className="form-flex">
                  <div className="block-creator">
                    <label htmlFor="nombre">Nombre del producto</label>
                    <input
                      id="nombre"
                      type="text"
                      name="nombre"
                      className="inputAncho"
                      // defaultValue={selectedRowData.NOMBRE}
                      {...register2('nombre', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="codigo">Código</label>
                    <input
                      id="codigo"
                      type="text"
                      name="codigo"
                      defaultValue={selectedRowData.CODIGO}
                      {...register2('codigo', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Familia </label>

                    <Select
                      options={familiasOpts && familiasOpts}
                      name="idGrupo"
                      value={
                        !familia
                          ? familiasOpts.filter(function (element) {
                              return element.value === selectedRowData.ID_GRUPO;
                            })
                          : familiasOpts.filter(function (element) {
                              return element.value === familia;
                            })
                      }
                      className="inputAncho baseColorStyle "
                      placeholder="Familia"
                      onChange={(choice) => setFamilia(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirFamilia(choice.inputValue)}
                        >
                          + añadir familia
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="referencia_del_cliente">
                      {' '}
                      Referencia del proveedor{' '}
                    </label>
                    <input
                      id="referencia_del_cliente"
                      type="text"
                      name="refCliente"
                      defaultValue={selectedRowData.REFERENCIA_CLIENTE}
                      {...register2('refCliente', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="precio">Precio referencia</label>
                    <CurrencyInput
                      id="input-example"
                      name="precioOrigen"
                      decimalsLimit={2}
                      defaultValue={selectedRowData.PRECIO_ORIGEN}
                      decimalScale={2}
                      intlConfig={{
                        locale: 'de-DE',
                        currency: 'EUR',
                      }}
                      {...register2('precioOrigen', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label
                      style={{
                        background: '#be9ee2',
                        padding: '5px 7px',
                        borderRadius: '20px',
                      }}
                      htmlFor="precio"
                    >
                      Precio Venta
                    </label>
                    <CurrencyInput
                      id="input-example"
                      name="precio"
                      style={{
                        bacground: '#725de85e',
                      }}
                      decimalsLimit={2}
                      decimalScale={2}
                      defaultValue={selectedRowData.PRECIO}
                      intlConfig={{
                        locale: 'de-DE',
                        currency: 'EUR',
                      }}
                      {...register2('precio', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Unidad de venta </label>
                    <select
                      {...register2('idUnidadesVenta', {
                        required: false,
                      })}
                      name="idUnidadesVenta"
                    >
                      {dataCombos &&
                        dataCombos['unidades'].map((dato) => {
                          return (
                            <option value={dato.id} key={dato.id}>
                              {dato.nombre}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="block-creator">
                    <label htmlFor="idVariedad"> Categoría </label>
                    {/* <select
                      name="idVariedad"
                      {...register2("idVariedad", {
                        required: false,
                      })}
                    >
                      {dataCombos &&
                        dataCombos["variedad"].map((dato) => {
                          return (
                            <option value={dato.id} key={dato.id}>
                              {dato.nombre}
                            </option>
                          );
                        })}
                    </select> */}
                    <Select
                      options={
                        dataCombos &&
                        dataCombos['variedad'].map((client, index) => {
                          return {
                            label: client.nombre,
                            value: client.id,
                            key: index,
                          };
                        })
                      }
                      value={
                        !categoria
                          ? categoriasOpts.filter(function (element) {
                              return (
                                element.value === selectedRowData.ID_VARIEDAD
                              );
                            })
                          : categoriasOpts.filter(function (element) {
                              return element.value === categoria;
                            })
                      }
                      placeholder="Categoría"
                      onChange={(choice) => setCategoria(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirCategoria(choice.inputValue)}
                        >
                          + añadir categoría
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Proveedor </label>

                    <Select
                      options={proveedoresOpts && proveedoresOpts}
                      name="idProveedor"
                      // defaultValue={selectedRowData.ID_PROVEEDOR}
                      value={
                        !proveedor
                          ? proveedoresOpts.filter(function (option) {
                              return (
                                option.value === selectedRowData.ID_PROVEEDOR
                              );
                            })
                          : proveedoresOpts.filter(function (option) {
                              return option.value === proveedor;
                            })
                      }
                      className="inputAncho"
                      onChange={(choice) => setProveedor(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() =>
                            navigate(
                              `/admin/gestion-proveedores/${choice.inputValue}`
                            )
                          }
                        >
                          + añadir proveedor
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Calibre </label>

                    <Select
                      options={calibresOpts && calibresOpts}
                      name="idCalibre"
                      value={
                        !calibre
                          ? calibresOpts.filter(function (element) {
                              return (
                                element.value === selectedRowData.ID_CALIBRE
                              );
                            })
                          : calibresOpts.filter(function (element) {
                              return element.value === calibre;
                            })
                      }
                      onChange={(choice) => setCalibre(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirCalibre(choice.inputValue)}
                        >
                          + añadir calibre
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="">Marca</label>
                    <input
                      id="idMarca"
                      type="text"
                      name="marca"
                      // defaultValue={selectedRowData.MARCA && selectedRowData.MARCA}
                      {...register2('marca', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Tara </label>
                    <Select
                      options={tarasOpts && tarasOpts}
                      name="idFormato"
                      defaultValue={selectedRowData.ID_FORMATO}
                      value={
                        !taras
                          ? tarasOpts.filter(function (option) {
                              return (
                                option.value === selectedRowData.ID_FORMATO
                              );
                            })
                          : tarasOpts.filter(function (option) {
                              return option.value === taras;
                            })
                      }
                      onChange={(choice) => setTaras(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button onClick={() => añadirTara(choice.inputValue)}>
                          + añadir tara
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Nº de bultos x palet </label>
                    <input
                      type="number"
                      name="idBultos"
                      // defaultValue={selectedRowData.ID_BULTOS}

                      {...register2('idBultos', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor=""> Disponibilidad en bultos </label>
                    <input
                      type="number"
                      name="disponibilidadInicial"
                      id="disponibilidadInicial"
                      {...register2('disponibilidadInicial', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="precio-origen">
                      {' '}
                      Fecha vigencia tarifa{' '}
                    </label>

                    <input
                      type="datetime-local"
                      name="fecha_vigencia"
                      id="fechaVigenciaTarifa"
                      // value={currentDateTime}
                      onChange={handleInputChange}
                      {...register2('fechaVigenciaTarifa', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="canarias">Canarias</label>
                    <div className="checkbox-wrapper">
                      <input
                        id="canarias"
                        type="checkbox"
                        name="canarias"
                        {...register2('canarias', {
                          required: false,
                        })}
                      />
                      <svg viewBox="0 0 35.6 35.6">
                        <circle
                          r="17.8"
                          cy="17.8"
                          cx="17.8"
                          className="background"
                        ></circle>
                        <circle
                          r="14.37"
                          cy="17.8"
                          cx="17.8"
                          className="stroke"
                        ></circle>
                        <polyline
                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                          className="check"
                        ></polyline>
                      </svg>
                    </div>
                  </div>
                  <div className="block-creator">
                    <label htmlFor=""> Estado</label>
                    <span>
                      {selectedRowData.ACTUALIZADO === 1 &&
                        'Pendiente precio de venta'}
                    </span>
                    <span>
                      {selectedRowData.ACTUALIZADO === 2 &&
                        'Pendiente de pasar a tarifa'}
                    </span>
                    <span>
                      {selectedRowData.ACTUALIZADO === 3 && 'En tarifa'}
                    </span>
                    <span>
                      {selectedRowData.ACTUALIZADO === 9 && 'No disponible'}
                    </span>
                    <span>
                      {selectedRowData.ACTUALIZADO === null && 'Sin Estado'}
                    </span>
                  </div>

                  <div className="content-images">
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <input
                        required
                        type="submit"
                        value="Actualizar producto"
                      />
                      <button
                        className="buttonAltaCliente h-100"
                        style={{ background: '#5be64ce7' }}
                        onClick={crearaPartir}
                      >
                        Crear nuevo producto a partir de este
                      </button>
                    </div>
                    <label className="custum-file-upload" htmlFor="file">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill=""
                          viewBox="0 0 24 24"
                        >
                          <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                          <g
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            id="SVGRepo_tracerCarrier"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {' '}
                            <path
                              fill=""
                              d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                              clipRule="evenodd"
                              fillRule="evenodd"
                            ></path>{' '}
                          </g>
                        </svg>
                      </div>
                      <div className="text">
                        <span htmlFor="file">Click para cargar imagen</span>
                      </div>
                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleImagenChange}
                        multiple
                      />
                    </label>
                  </div>

                  <div className="galeria-imagenes">
                    {imagenes &&
                      imagenes.map((imagen, index) => (
                        <div className="imagen-container" key={index}>
                          <img
                            src={
                              imagen.startsWith('http')
                                ? `${imagen}?${new Date().getTime()}`
                                : imagen
                            }
                            alt={`Imagen ${index}`}
                          />

                          <div id="image-principal-radio-container">
                            <input
                              type="radio"
                              name="imagenPrincipal"
                              // checked={imagenPrincipal===imagen}
                              defaultChecked={imagenPrincipal === imagen}
                              onChange={() =>
                                seleccionarImagenPrincipal(imagen)
                              }
                              id="image-principal-radio"
                            />{' '}
                            Imagen Principal
                          </div>
                          <div
                            className="eliminar-icon"
                            onClick={() => eliminarImagen(index)}
                          >
                            <RiCloseCircleLine />
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* <input required type="submit" value="Actualizar producto" /> */}
                </div>
              </div>
            )}
          </form>
        </DialogContent>
      </Dialog>

      {/* ================ Dialog Nuevo producto ========================== */}

      <Dialog
        open={openNewProductForm}
        onClose={closeNewProductDialog}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              boxShadow: 'none',
            },
          },
        }}
      >
        <div
          className="switch-button"
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          data="Oferta Flash"
        >
          <input
            type="checkbox"
            name="switch-button"
            id="switch-label"
            className="switch-button__checkbox"
            onClick={handleBlinkCrearPropducto}
          />

          <label
            htmlFor="switch-label"
            className="switch-button__label"
          ></label>
        </div>
        <AiFillCloseCircle
          className="close-dialog-articulo"
          onClick={closeNewProductDialog}
        />
        <MdLocalOffer
          className="local-offer"
          style={{
            fontSize: '35px',
            position: 'absolute',
            right: '80px',
            top: '10px',
            color: '#702fb9ad',
            cursor: 'pointer',
          }}
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          data="Oferta Especial"
          // onClick={() =>
          //   navigate(`/admin/crear-oferta-especial/${selectedRowData.CODIGO}`)
          // }
        />
        <DialogTitle style={{ textAlign: 'center', color: '#370570' }}>
          Creación de Producto
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(crearProducto)}>
            {form && (
              <div>
                <div className="form-flex">
                  <div className="block-creator">
                    <label htmlFor="nombre">Nombre del producto</label>
                    <input
                      id="nombre"
                      type="text"
                      name="nombre"
                      {...register('nombre', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="codigo">Código</label>
                    <input
                      id="codigo"
                      type="text"
                      name="codigo"
                      {...register('codigo', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Familia</label>
                    <Select
                      options={
                        dataCombos &&
                        dataCombos['familias'].map((client, index) => {
                          return {
                            label: client.nombre,
                            value: client.id,
                            key: index,
                          };
                        })
                      }
                      className="inputAncho baseColorStyle "
                      placeholder="Familia"
                      onChange={(choice) => setFamilia(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirFamilia(choice.inputValue)}
                        >
                          + añadir familia
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="referencia_del_cliente">
                      {' '}
                      Referencia del proveedor{' '}
                    </label>
                    <input
                      id="referencia_del_cliente"
                      type="text"
                      name="referencia_del_cliente"
                      {...register('refCliente', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="precio-origen"> Precio referencia </label>

                    <CurrencyInput
                      id="input-example"
                      name="precioOrigen"
                      decimalsLimit={2}
                      decimalScale={2}
                      defaultValue={0}
                      intlConfig={{
                        locale: 'de-DE',
                        currency: 'EUR',
                      }}
                      {...register('precioOrigen', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="precio"> Precio </label>

                    <CurrencyInput
                      id="input-example"
                      name="precio"
                      decimalsLimit={2}
                      decimalScale={2}
                      defaultValue={0}
                      intlConfig={{
                        locale: 'de-DE',
                        currency: 'EUR',
                      }}
                      {...register('precio', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Unidad de venta </label>
                    <select
                      {...register('idUnidadesVenta', {
                        required: false,
                      })}
                      name="idUnidadesVenta"
                    >
                      {dataCombos &&
                        dataCombos['unidades'].map((dato) => {
                          return (
                            <option value={dato.id} key={dato.id}>
                              {dato.nombre}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Categoría </label>

                    <Select
                      options={
                        dataCombos &&
                        dataCombos['variedad'].map((client, index) => {
                          return {
                            label: client.nombre,
                            value: client.id,
                            key: index,
                          };
                        })
                      }
                      placeholder="Categoría"
                      onChange={(choice) => setCategoria(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirCategoria(choice.inputValue)}
                        >
                          + añadir categoría
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Proveedor </label>

                    <Select
                      options={proveedoresOpts && proveedoresOpts}
                      name="idProveedor"
                      onChange={(choice) => setProveedor(choice.value)}
                      className="inputAncho baseColorStyle "
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() =>
                            navigate(
                              `/admin/gestion-proveedores/${choice.inputValue}`
                            )
                          }
                        >
                          + añadir proveedor
                        </button>
                      )}
                      placeholder="Proveedor"
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Calibre </label>
                    <Select
                      options={
                        dataCombos &&
                        dataCombos['calibre'].map((client, index) => {
                          return {
                            label: client.nombre,
                            value: client.id,
                            key: index,
                          };
                        })
                      }
                      placeholder="Calibre"
                      onChange={(choice) => setCalibre(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button
                          onClick={() => añadirCalibre(choice.inputValue)}
                        >
                          + añadir calibre
                        </button>
                      )}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="">Marca</label>
                    <input
                      id="idMarca"
                      type="text"
                      name="marca"
                      {...register('marca', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Tara </label>

                    <Select
                      options={tarasOpts && tarasOpts}
                      name="idFormato"
                      onChange={(choice) => setTaras(choice.value)}
                      noOptionsMessage={(choice) => (
                        <button onClick={() => añadirTara(choice.inputValue)}>
                          + añadir tara
                        </button>
                      )}
                      placeholder="Tara"
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor=""> Nº de bultos x palet </label>
                    <input
                      type="number"
                      name="idBultos"
                      // defaultValue={selectedRowData.ID_BULTOS}

                      {...register('idBultos', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor=""> Disponibilidad en bultos </label>
                    <input
                      type="number"
                      name="disponibilidadInicial"
                      id="disponibilidadInicial"
                      {...register('disponibilidadInicial', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="precio-origen">
                      {' '}
                      Fecha vigencia tarifa{' '}
                    </label>

                    <input
                      type="datetime-local"
                      name="fecha_vigencia"
                      id="fecha_vigencia"
                      value={currentDateTime}
                      onChange={handleInputChange}
                      {...register('fechaVigenciaTarifa', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="canarias">Canarias</label>
                    <div className="checkbox-wrapper">
                      <input
                        id="canarias"
                        type="checkbox"
                        name="canarias"
                        {...register('canarias', {
                          required: false,
                        })}
                      />
                      <svg viewBox="0 0 35.6 35.6">
                        <circle
                          r="17.8"
                          cy="17.8"
                          cx="17.8"
                          className="background"
                        ></circle>
                        <circle
                          r="14.37"
                          cy="17.8"
                          cx="17.8"
                          className="stroke"
                        ></circle>
                        <polyline
                          points="11.78 18.12 15.55 22.23 25.17 12.87"
                          className="check"
                        ></polyline>
                      </svg>
                    </div>
                  </div>

                  <div className="content-images">
                    <div>
                      <input required type="submit" value="Crear producto" />
                    </div>
                    <label className="custum-file-upload" htmlFor="file">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill=""
                          viewBox="0 0 24 24"
                        >
                          <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                          <g
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            id="SVGRepo_tracerCarrier"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {' '}
                            <path
                              fill=""
                              d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                              clipRule="evenodd"
                              fillRule="evenodd"
                            ></path>{' '}
                          </g>
                        </svg>
                      </div>
                      <div className="text">
                        <span>Click para cargar imagen</span>
                      </div>
                      <input
                        // required
                        type="file"
                        id="file"
                        name="imagenes"
                        onChange={handleImagenChange}
                        multiple
                      />
                    </label>
                  </div>

                  <div className="galeria-imagenes">
                    {imagenes &&
                      imagenes.map((imagen, index) => (
                        <div className="imagen-container" key={index}>
                          <img src={imagen} alt={`Imagen ${index}`} />
                          <div
                            className="eliminar-icon"
                            onClick={() => eliminarImagen(index)}
                          >
                            <RiCloseCircleLine />
                          </div>
                        </div>
                      ))}
                  </div>

                  {/* <input required type="submit" value="Crear producto" /> */}
                </div>
              </div>
            )}
          </form>
        </DialogContent>
      </Dialog>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>{textPopover}</Typography>
      </Popover>
    </div>
  );
}

export default DataTable;
