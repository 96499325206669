import { Menu } from '../../../../components/Clientes/Menu/Menu';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './MiCuenta.css';

export const MiCuenta = () => {
  // eslint-disable-next-line
  const [data, setData] = useState([]);
  const {
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm();

  function actualizarDatos(data) {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    data['id'] = userId;
    fetch(`${process.env.REACT_APP_API}/portActClientes.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    }).then((response) => {
      if (response.ok) {
        Swal.fire({
          text: '¡Actualizado exitosamente!',
          type: 'success',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        getDefaultData();
      } else {
        Swal.fire({
          text: '¡Error al actualizar!',
          type: 'error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
  }

  function getDefaultData() {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portClientes.php?id=${userId}`)
      .then((data) => data.json())
      .then((data) => {
        setData(data.data[0]);
        let datos = data.data[0];
        if (datos) {
          setValue('cPostal', datos.CPOSTAL);
          setValue('contacto', datos.CONTACTO);
          setValue('direccion', datos.DIRECCION);
          setValue('email', datos.EMAIL);
          setValue('nombreComercial', datos.NOMBRE_COMERCIAL);
          setValue('password', datos.PASSWORD);
          setValue('poblacion', datos.POBLACION);
          setValue('provincia', datos.PROVINCIA);
          setValue('razonSocial', datos.RAZON_SOCIAL);
          setValue('tel1', datos.TELEFONO1);
          setValue('tel2', datos.TELEFONO2);
        }
      });
  }

  useEffect(() => {
    getDefaultData();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Menu ruta={'mi-cuenta'} />
      <div className="container-mi-cuenta-cliente">
        <div className="cabecera-mi-cuenta">
          <h2>¡Bienvenido Cliente!</h2>
        </div>
        <div className="form-actualizar-mi-cuenta">
          <form
            className="form-micuenta"
            onSubmit={handleSubmit(actualizarDatos)}
          >
            <div className="header-form">
              <h1>ACTUALIZAR MI CUENTA</h1>
            </div>
            <div className="campos">
              <div className="block-creator">
                <label htmlFor="contacto"> Contacto </label>
                <input
                  id="contacto"
                  type="text"
                  name="contacto"
                  // defaultValue={data && data.CONTACTO}
                  {...register('contacto', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="nombreComercial"> Nombre Comercial </label>
                <input
                  id="nombreComercial"
                  type="text"
                  name="nombreComercial"
                  // defaultValue={data && data.NOMBRE_COMERCIAL}
                  {...register('nombreComercial', {
                    required: false,
                  })}
                />
              </div>

              <div className="block-creator">
                <label htmlFor="email"> Email </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  // defaultValue={data && data.EMAIL}
                  {...register('email', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="direccion"> Dirección</label>
                <input
                  id="direccion"
                  type="text"
                  name="direccion"
                  // defaultValue={data && data.DIRECCION}
                  {...register('direccion', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="cPostal"> C.Postal </label>
                <input
                  id="cPostal"
                  type="text"
                  name="cPostal"
                  // defaultValue={data && data.CPOSTAL}
                  {...register('cPostal', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="poblacion"> Población </label>
                <input
                  id="poblacion"
                  type="text"
                  name="poblacion"
                  // defaultValue={data && data.POBLACION}
                  {...register('poblacion', {
                    required: false,
                  })}
                />
              </div>

              <div className="block-creator">
                <label htmlFor="provincia"> Provincia </label>
                <input
                  id="provincia"
                  type="text"
                  name="provincia"
                  // defaultValue={data && data.PROVINCIA}
                  {...register('provincia', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="tel1"> Teléfono 1 </label>
                <input
                  id="tel1"
                  type="text"
                  name="tel1"
                  // defaultValue={data && data.TELEFONO1}
                  {...register('tel1', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="tel2"> Teléfono 2 </label>
                <input
                  id="tel2"
                  type="text"
                  name="tel2"
                  // defaultValue={data && data.TELEFONO2}
                  {...register('tel2', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="razonSocial"> Razón Social </label>
                <input
                  id="razonSocial"
                  type="text"
                  name="razonSocial"
                  // defaultValue={data && data.RAZON_SOCIAL}
                  {...register('razonSocial', {
                    required: false,
                  })}
                />
              </div>
              <div className="block-creator">
                <label htmlFor="password"> Contraseña </label>
                <input
                  id="password"
                  type="password"
                  name="password"
                  // defaultValue={data && data.PASSWORD}
                  {...register('password', {
                    required: false,
                  })}
                />
              </div>
            </div>
            <div className="mi-cuenta-submit">
              <input type="submit" value="Actualizar" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
