import './FilterBar.css';

export const FilterBar = () => {
  return (
    <div className="filter-bar">
      <div className="block-filter">
        <select name="" id="">
          <option value="0">Frutas</option>
          <option value="0">Verdura</option>
        </select>
      </div>

      <div className="block-filter">
        <select name="" id="">
          <option value="0">Importación</option>
          <option value="0">Verdura</option>
        </select>
      </div>

      <div className="block-filter">
        <select name="" id="">
          <option value="0">Precio</option>
          <option value="0">Verdura</option>
        </select>
      </div>

      <div className="block-filter">
        <select name="" id="">
          <option value="0">Categoría</option>
          <option value="0">Verdura</option>
        </select>
      </div>
    </div>
  );
};
