import { useState } from 'react';
import DataTable from '../../../../components/Auth/Admin/DataTable/DataTable';
import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { useEffect } from 'react';

export const Usuarios = () => {
  const [data, setData] = useState([]);

  const columnas = [
    { field: 'ID', headerName: 'ID', width: 90 },
    { field: 'LOGIN', headerName: 'Login', width: 100, editable: true },
    { field: 'NOMBRE', headerName: 'Nombre', width: 140, editable: true },
    { field: 'APELLIDOS', headerName: 'Apellidos', width: 140, editable: true },
    { field: 'EMAIL', headerName: 'Email', width: 220, editable: true },
    { field: 'PASSWORD', headerName: 'Contraseña', width: 150, editable: true },
    {
      field: 'ID_PROFILE',
      headerName: 'Rol de usuario',
      width: 160,
      valueGetter: (params) => {
        if (params.row.ID_PERFIL === 1) {
          return 'Administrador';
        } else if (params.row.ID_PERFIL === 2) {
          return 'Cliente';
        } else if (params.row.ID_PERFIL === 3) {
          return 'Proveedor';
        } else {
          return 'Sin Rol';
        }
      },
    },
  ];

  function getDataApi() {
    fetch(`${process.env.REACT_APP_API}/portUsers.php`)
      .then((res) => res.json())
      .then((json) => {
        if (json.data.length > 0) {
          setData(json.data);
        }
      });
  }

  useEffect(() => {
    getDataApi();
  }, []);

  return (
    <div>
      <Header />
      <div className="data-grid">
        <DataTable datos={data && data} columnas={columnas} />
      </div>
    </div>
  );
};
