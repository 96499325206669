import React, { useEffect, useState } from 'react';
import './CrearArticulos.css';
import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { RiCloseCircleLine } from 'react-icons/ri';

import { useForm } from 'react-hook-form';
export const CrearArticulos = () => {
  const [imagenes, setImagenes] = useState([]);
  const [buscador, setBuscador] = useState('');
  const [combos, setCombos] = useState('');
  const [dataCombos, setDataCombos] = useState(null);

  const { register, handleSubmit, reset } = useForm();

  const handleImagenChange = (event) => {
    const files = event.target.files;
    const imagenesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imagenesArray.push(e.target.result);
        if (imagenesArray.length === files.length) {
          setImagenes((prevImagenes) => [...prevImagenes, ...imagenesArray]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };
  const eliminarImagen = (index) => {
    setImagenes((prevImagenes) => prevImagenes.filter((_, i) => i !== index));
  };

  const handleSubmitform = (data) => {
    data['imagenes'] = imagenes;
    reset();
    setImagenes([]);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      console.log(buscador);
    }
  };

  function getCombos() {
    fetch(`${process.env.REACT_APP_API}/portCombosArticulo.php`)
      .then((res) => res.json())
      .then((json) => {
        setCombos(json);
      });
  }

  function getDataCombos() {
    fetch(`${process.env.REACT_APP_API}/portDesplegables.php`)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);
      });
  }

  useEffect(() => {
    getCombos();
    getDataCombos();
  }, []);

  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    setCurrentDateTime(getCurrentDateTime());
  }, []);

  function getCurrentDateTime() {
    const spainTimezoneOffset = 2; // Offset de tiempo de España en horas (puede variar debido a DST)
    const currentTime = new Date();
    const adjustedTime = new Date(
      currentTime.getTime() + spainTimezoneOffset * 60 * 60 * 1000
    );
    return adjustedTime.toISOString().slice(0, 16); // Formato YYYY-MM-DDTHH:MM
  }

  function handleInputChange(e) {
    setCurrentDateTime(e.target.value);
  }
  return (
    <div>
      <Header />
      <div className="crear-articulos-container">
        <div className="container-input">
          <input
            type="text"
            placeholder="Buscar..."
            name="text"
            className="input-search"
            onChange={(e) => {
              setBuscador(e.target.value);
            }}
            onKeyDown={handleKeyPress}
          />
          <svg
            fill="#000000"
            width="20px"
            height="20px"
            viewBox="0 0 1920 1920"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M790.588 1468.235c-373.722 0-677.647-303.924-677.647-677.647 0-373.722 303.925-677.647 677.647-677.647 373.723 0 677.647 303.925 677.647 677.647 0 373.723-303.924 677.647-677.647 677.647Zm596.781-160.715c120.396-138.692 193.807-319.285 193.807-516.932C1581.176 354.748 1226.428 0 790.588 0S0 354.748 0 790.588s354.748 790.588 790.588 790.588c197.647 0 378.24-73.411 516.932-193.807l516.028 516.142 79.963-79.963-516.142-516.028Z"
              fillRule="evenodd"
            ></path>
          </svg>
        </div>

        <form
          action=""
          className="creator-product-form"
          onSubmit={handleSubmit(handleSubmitform)}
        >
          <h3>Crear un nuevo artículo</h3>
          <br />
          <div className="form-flex">
            <div className="block-creator">
              <label htmlFor="codigo">Código</label>
              <input
                id="codigo"
                required
                type="text"
                name="codigo"
                {...register('codigo', {
                  required: true,
                })}
              />
            </div>

            <div className="block-creator">
              <label htmlFor="nombre">Nombre del producto</label>
              <input
                id="nombre"
                required
                type="text"
                name="nombre"
                {...register('nombre', {
                  required: true,
                })}
              />
            </div>

            {/* Selectores */}
            <div className="block-creator">
              <label htmlFor=""> Variedad </label>
              <select>
                {dataCombos &&
                  dataCombos['variedad'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="block-creator">
              <label htmlFor="grupo">Grupo</label>
              <input
                id="grupo"
                required
                type="text"
                name="grupo"
                {...register('grupo', {
                  required: true,
                })}
              />
            </div>

            <div className="block-creator">
              <label htmlFor=""> Código de proveedor </label>
              <select>
                {combos &&
                  combos.variedad.map((dato, index) => {
                    return (
                      <option value={dato} key={index}>
                        {dato}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="block-creator">
              <label htmlFor=""> Calibre </label>
              <select>
                {dataCombos &&
                  dataCombos['calibre'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="block-creator">
              <label htmlFor=""> Marca </label>
              <select>
                {dataCombos &&
                  dataCombos['marca'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="block-creator">
              <label htmlFor=""> Formato </label>
              <select>
                {dataCombos &&
                  dataCombos['formato'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="block-creator">
              <label htmlFor=""> Nº de bultos por Palet </label>
              <select>
                {dataCombos &&
                  dataCombos['bultos'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="block-creator">
              <label htmlFor=""> Disponibilidad (bultos) </label>
              <select>
                {dataCombos &&
                  dataCombos['disponibilidad'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="block-creator">
              <label htmlFor="precio"> Precio propuesto</label>
              <input
                id="precio"
                required
                step="any"
                type="number"
                name="precio"
                {...register('precio', {
                  required: true,
                })}
              />
            </div>
            <div className="block-creator">
              <label htmlFor="precio-origen"> Precio venta </label>
              <input
                id="precio-origen"
                required
                step="any"
                type="number"
                name="precio-origen"
                {...register('precio-origen', {
                  required: true,
                })}
              />
            </div>

            {/* Selectores */}
            <div className="block-creator">
              <label htmlFor=""> Unidad de venta </label>
              <select name="" id="">
                {dataCombos &&
                  dataCombos['unidades'].map((dato) => {
                    return (
                      <option value={dato.id} key={dato.id}>
                        {dato.nombre}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="block-creator">
              <label htmlFor="precio-origen"> Fecha vigencia tarifa </label>

              <input
                type="datetime-local"
                name="fecha_vigencia"
                id="fecha_vigencia"
                value={currentDateTime}
                onChange={handleInputChange}
              />
            </div>

            {/* Imagenes */}
            <div className="content-images">
              <label className="custum-file-upload" htmlFor="file">
                <div className="icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill=""
                    viewBox="0 0 24 24"
                  >
                    <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                    <g
                      strokeLinejoin="round"
                      strokeLinecap="round"
                      id="SVGRepo_tracerCarrier"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {' '}
                      <path
                        fill=""
                        d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                        clipRule="evenodd"
                        fillRule="evenodd"
                      ></path>{' '}
                    </g>
                  </svg>
                </div>
                <div className="text">
                  <span>Click para cargar imagen</span>
                </div>
                <input
                  required
                  type="file"
                  id="file"
                  name="imagenes"
                  onChange={handleImagenChange}
                  multiple
                />
              </label>
            </div>

            <div className="galeria-imagenes">
              {imagenes.map((imagen, index) => (
                <div className="imagen-container" key={index}>
                  <img src={imagen} alt={`Imagen ${index}`} />
                  <div
                    className="eliminar-icon"
                    onClick={() => eliminarImagen(index)}
                  >
                    <RiCloseCircleLine />
                  </div>
                </div>
              ))}
            </div>

            <input required type="submit" value="Crear producto" />
          </div>
        </form>
      </div>
    </div>
  );
};
