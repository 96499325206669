import { DataTableProveedor } from '../../../components/Proveedor/DataTableProveedor/DataTableProveedor';
import { HeaderProveedor } from '../../../components/Proveedor/HeaderProveedor/HeaderProveedor';
import './Proveedor.css';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../../../context/DataContext';

export const Proveedor = () => {
  const [data, setData] = useState([]);
  const { estadoArticulos } = useContext(DataContext);
  // eslint-disable-next-line
  const [updateArticulos, setUpdateArticulos] = estadoArticulos;

  let user = window.localStorage.getItem('user');
  user = JSON.parse(user);
  const ID_PROVEEDOR = user[0].ID;

  function FormatearMonedaEuro(numero) {
    // Formatea el número como euros con dos decimales
    return numero.toLocaleString('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });
  }
  const columnas = [
    {
      field: 'img',
      headerName: 'Imagen',
      width: 200,
      height: 500,
      renderCell: (params) => (
        <img
          style={{ width: '100%', height: '100%' }}
          src={params.value}
          alt=""
        />
      ),
    },
    { field: 'ID_GRUPO', headerName: 'Grupo', width: 100, height: 500 },
    { field: 'CODIGO', headerName: 'Código', width: 140, height: 500 },
    { field: 'NOMBRE', headerName: 'Nombre', width: 220, height: 500 },
    {
      field: 'ID_UNIDADES_VENTA',
      headerName: 'Unidad de venta',
      width: 100,
      height: 500,
    },

    {
      field: 'NOMBRE_UNIDAD_VENTA',
      headerName: 'Unidad de venta',
      width: 160,
      height: 500,
    },
    {
      field: 'DISPONIBILIDAD_INICIAL',
      headerName: 'Stock',
      width: 200,
      height: 500,
    },

    {
      field: 'PRECIO_ORIGEN',
      headerName: 'Precio',
      width: 150,
      height: 500,
      valueGetter: (params) => {
        return FormatearMonedaEuro(params.row.PRECIO_ORIGEN);
      },
    },
    { field: 'ID', headerName: 'ID', width: 90, height: 500 },
  ];

  function getDataApi() {
    fetch(
      `${process.env.REACT_APP_API}/portArticulos.php?idProveedor=${ID_PROVEEDOR}`
    )
      .then((res) => res.json())
      .then((json) => {
        setData(json.data);
      });
  }

  useEffect(() => {
    getDataApi();
    // eslint-disable-next-line
  }, [updateArticulos]);

  return (
    <div className="proveedor-container">
      <HeaderProveedor />

      <div className="container-proveedor-datagrid">
        <DataTableProveedor
          datos={data && data}
          columnas={columnas}
          alturaCelda={120}
          form={true}
        />
      </div>
    </div>
  );
};
