import { useState } from 'react';
import DataTable from '../../../../components/Auth/Admin/DataTable/DataTable';
import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { DataContext } from '../../../../context/DataContext';
import { useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import defaultImgError from '../../../../assets/defaultImg.jpg';

import React from 'react';
export const Articulos = () => {
  const { familia } = useParams();
  // const [data, setData] = useState([]);
  const [isMobile, setIsMobile] = useState(null);
  const { estadoArticulos } = useContext(DataContext);
  // eslint-disable-next-line
  const [updateArticulos, setUpdateArticulos] = estadoArticulos;

  const { estadoArticuloIdContextData } = useContext(DataContext);
  // eslint-disable-next-line
  const [estadoArticuloIdContext, setEstadoArticuloIdContext] =
    estadoArticuloIdContextData;
  const { proveedorContextFilter } = useContext(DataContext);
  // eslint-disable-next-line
  const [proveedorContext, setProveedorContext] = proveedorContextFilter;

  // eslint-disable-next-line
  const { datosArticulosGrid } = useContext(DataContext);
  // eslint-disable-next-line
  const [datosArticulos, setDatosArticulos] = datosArticulosGrid;

  const deviceDetector = () => {
    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    console.log(isMobile);
    setIsMobile(isMobile);
  };
  useEffect(() => {
    deviceDetector();
  }, []);
  function FormatearMonedaEuro(numero) {
    // Formatea el número como euros con dos decimales
    return numero.toLocaleString('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2,
    });
  }
  const handleErrorImgDef = (e) => {
    e.target.src = defaultImgError;
  };
  const columnas = [
    {
      field: 'IMAGEN_PRINCIPAL',
      headerName: 'Imagen',
      width: isMobile ? 100 : 100,
      height: 100,
      editable: true,
      renderCell: (params) => (
        <img
          style={{ width: '100%', height: '100%' }}
          src={`http://api.frutascerdan.com/cerdan_imagenes/${params.value}?${new Date().getTime()}`}
          onError={handleErrorImgDef}
          alt=""
        />
      ),
    },

    { field: 'ID_GRUPO', headerName: 'Familia', width: 100, height: 500 },
    {
      field: 'NOMBRE_GRUPO',
      headerName: 'Familia',
      width: 200,
      height: 500,
      editable: true,
    },

    {
      field: 'CODIGO',
      headerName: 'Código',
      width: 140,
      height: 500,
      editable: true,
    },
    {
      field: 'REFERENCIA_CLIENTE',
      headerName: 'Referencia',
      width: 140,
      height: 200,
    },
    {
      field: 'NOMBRE',
      headerName: 'Nombre',
      width: isMobile ? 220 : 250,
      height: 500,
      editable: true,
    },
    {
      field: 'PROVEEDOR',
      headerName: 'Proveedor',
      editable: false,
      type: 'boolean',
      width: isMobile ? 90 : 250,
      height: 200,
      renderCell: (params) => (
        <div>
          <span>{params.row.PROVEEDOR}</span>
        </div>
      ),
    },

    {
      field: 'ID_BULTOS',
      headerName: 'Bultos',
      width: isMobile ? 70 : 70,
      height: 500,
      editable: true,
    },
    {
      field: 'PRECIO_ORIGEN',
      headerName: 'Precio Ref',
      width: 130,
      height: 200,
      editable: true,
      valueGetter: (params) => {
        return FormatearMonedaEuro(params.row.PRECIO_ORIGEN);
      },
    },
    {
      field: 'PRECIO',
      headerName: 'Precio de venta',
      width: 150,
      height: 200,
      editable: true,
      valueGetter: (params) => {
        return FormatearMonedaEuro(params.row.PRECIO);
      },
    },
    { field: 'ID', headerName: 'ID', width: 90, height: 500 },
    {
      field: 'NOMBRE_UNIDAD_VENTA',
      headerName: 'Uds.Venta',
      width: 100,
      height: 200,
      editable: false,
    },
    {
      field: 'DISPONIBILIDAD_INICIAL',
      headerName: 'Stock',
      editable: true,
      width: 100,
      height: 200,
    },
    {
      field: 'TARA',
      headerName: 'Tara',
      editable: false,
      width: 100,
      height: 200,
    },
    {
      field: 'CALIBRE',
      headerName: 'Calibre',
      editable: false,
      width: 100,
      height: 200,
    },
    {
      field: 'VARIEDAD',
      headerName: 'Categoria',
      editable: false,
      width: 100,
      height: 200,
    },

    {
      field: 'CANARIAS',
      headerName: 'Canarias',
      editable: true,
      type: 'boolean',
      width: isMobile ? 90 : 130,
      height: 200,
      renderCell: (params) => (
        <div>
          <div className="checkbox-wrapper">
            <input
              id="canarias"
              type="checkbox"
              name="canarias"
              defaultChecked={params.row.CANARIAS}
              disabled
            />
            <svg viewBox="0 0 35.6 35.6">
              <circle
                r="17.8"
                cy="17.8"
                cx="17.8"
                className="background"
              ></circle>
              <circle r="14.37" cy="17.8" cx="17.8" className="stroke"></circle>
              <polyline
                points="11.78 18.12 15.55 22.23 25.17 12.87"
                className="check"
              ></polyline>
            </svg>
          </div>
        </div>
      ),
    },
    {
      field: 'BLINK',
      headerName: 'Oferta Flash',
      editable: true,
      type: 'boolean',
      width: isMobile ? 90 : 170,
      height: 200,
      renderCell: (params) => (
        <div>
          {params.row.BLINK ? (
            <span
              style={{
                border: '2px solid #6AE85D',
                color: '#6AE85D',
                padding: '5px 10px',
                borderRadius: '40px',
              }}
            >
              Activo
            </span>
          ) : (
            <span
              style={{
                border: '2px solid #E85D63',
                color: '#E85D63',
                padding: '5px 10px',
                borderRadius: '40px',
              }}
            >
              Inactivo
            </span>
          )}
        </div>
      ),
    },
    {
      field: 'ACTUALIZADO',
      headerName: 'Estado',
      editable: false,
      type: 'boolean',
      width: isMobile ? 90 : 250,
      height: 200,
      renderCell: (params) => (
        <div>
          <span>
            {params.row.ACTUALIZADO === 1 && 'Pendiente precio de venta'}
          </span>
          <span>
            {params.row.ACTUALIZADO === 2 && 'Pendiente de pasar a tarifa'}
          </span>
          <span>{params.row.ACTUALIZADO === 3 && 'En tarifa'}</span>
          <span>{params.row.ACTUALIZADO === 9 && 'No disponible'}</span>
          <span>{params.row.ACTUALIZADO === null && 'Sin Estado'}</span>
        </div>
      ),
    },
    {
      field: 'MARCA',
      headerName: 'Marca',
      width: isMobile ? 220 : 250,
      height: 500,
      editable: true,
    },
  ];

  function getDataApi(idEstado, proveedor) {
    let url;
    if (idEstado) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?estado=${idEstado}`;
    }
    if (proveedor) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?idProveedor=${proveedor}`;
    }
    if (familia) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?idFamilia=${familia}`;
    }
    if (familia && proveedor) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?idFamilia=${familia}&idProveedor=${proveedor}`;
    }
    if (idEstado && proveedor) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?estado=${idEstado}&idProveedor=${proveedor}`;
    }
    if (familia && idEstado) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?idFamilia=${familia}&estado=${idEstado}`;
    }
    if (familia && idEstado && proveedor) {
      url = `${process.env.REACT_APP_API}/portArticulos.php?idFamilia=${familia}&estado=${idEstado}&idProveedor=${proveedor}`;
    }

    if (!idEstado && !proveedor && !familia) {
      url = `${process.env.REACT_APP_API}/portArticulos.php`;
    }

    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setDatosArticulos(json.data);
      });
  }

  useEffect(() => {
    getDataApi(estadoArticuloIdContext, proveedorContext);
    // eslint-disable-next-line
  }, [updateArticulos, familia]);

  useEffect(() => {
    getDataApi(estadoArticuloIdContext, proveedorContext);
    // eslint-disable-next-line
  }, [estadoArticuloIdContext, proveedorContext, familia, updateArticulos]);

  return (
    <div>
      <Header />
      <div className="data-grid">
        <DataTable
          datos={datosArticulos && datosArticulos}
          columnas={columnas}
          alturaCelda={50}
          form={true}
        />
      </div>
    </div>
  );
};
