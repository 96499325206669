import './Admin.css';
import { Header } from '../../../components/Auth/Admin/Header/Header';
import Logo from '../../../assets/LogoColor2.png';
import { DataContext } from '../../../context/DataContext';
import { useEffect, useContext } from 'react';
export const Admin = () => {
  const { datosArticulosGrid } = useContext(DataContext);
  // eslint-disable-next-line
  const [datosArticulos, setDatosArticulos] = datosArticulosGrid;

  function getDataApiArticulos() {
    let url = `${process.env.REACT_APP_API}/portArticulos.php`;
    fetch(url)
      .then((res) => res.json())
      .then((json) => {
        setDatosArticulos(json.data);
      });
  }

  useEffect(() => {
    // eslint-disable-next-line
    getDataApiArticulos();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      {/* <div className="embed-container">
        <iframe
        title="Power Bi"
     
         
          src="https://app.powerbi.com/view?r=eyJrIjoiZmM3ZWY2ZTgtNjM3ZC00YzM5LTliMjgtYjM5ZDM5MWY2YTAzIiwidCI6IjkwMzg4YjdlLTVlZGItNDUwZi04OGExLWM1ZjhmNjhiYmU3NiIsImMiOjl9"
          frameBorder="0"
          allowFullScreen={true}
        ></iframe>
      </div> */}
      <img
        src={Logo}
        alt="logo"
        style={{
          position: 'absolute',
          top: '0',
          bottom: '0',
          left: '0',
          right: '0',
          margin: 'auto',
        }}
      />
    </div>
  );
};
