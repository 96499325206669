import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { DataGrid } from '@mui/x-data-grid';
import { Form } from '../../../../components/Auth/Admin/Form/Form';
import { MdOutlineError } from 'react-icons/md';
import './GestionProveedores.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const GestionProveedores = () => {
  const [data, setData] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [datosRow, setDatosRow] = useState(null);
  const { nombre } = useParams();

  const columnas = [
    {
      field: 'ID',
      headerName: 'ID',
      width: 90,
      cellClassName: 'hide-cell',
      headerClassName: 'hide-cell',
    },
    { field: 'CODIGO', headerName: 'Código', width: 100, editable: false },
    {
      field: 'NOMBRE_COMERCIAL',
      headerName: 'Nombre comercial',
      width: 300,
      editable: false,
    },
    {
      field: 'DIRECCION',
      headerName: 'Direccion',
      width: 300,
      editable: false,
    },
    { field: 'EMAIL', headerName: 'Email', width: 220, editable: false },
    {
      field: 'PASSWORD',
      headerName: 'Contraseña',
      width: 150,
      editable: false,
      cellClassName: 'hide-cell',
      headerClassName: 'hide-cell',
    },
    {
      field: 'PROVINCIA',
      headerName: 'Provincia',
      width: 120,
      editable: false,
    },
    {
      field: 'POBLACION',
      headerName: 'Población',
      width: 150,
      editable: false,
    },
    { field: 'TELEFONO1', headerName: 'Teléfono', width: 150, editable: false },
    {
      field: 'TELEFONO2',
      headerName: 'Teléfono 2',
      width: 150,
      editable: false,
    },

    {
      field: 'CPOSTAL',
      headerName: 'C.Postal',
      width: 150,
      editable: false,
    },
    {
      field: 'WEB',
      headerName: 'Web',
      width: 150,
      editable: false,
    },
    {
      field: 'CONTACTO',
      headerName: 'Contacto',
      width: 150,
      editable: false,
    },
    {
      field: 'RAZON_SOCIAL',
      headerName: 'Razón social',
      width: 150,
      editable: false,
    },
  ];

  function getDataApi() {
    fetch(`${process.env.REACT_APP_API}/portProveedores.php`)
      .then((res) => res.json())
      .then((json) => {
        if (json.data.length > 0) {
          setData(json.data);
        }
      });
  }

  useEffect(() => {
    getDataApi();
  }, [openForm]);

  function handleOpenForm() {
    setDatosRow(null);
    setOpenForm(true);
  }
  function handleOpenFormParams(params) {
    setOpenForm(true);
    setDatosRow(params.row);
  }

  function handleCloseForm() {
    setOpenForm(false);
  }

  function getProveedor() {
    if (nombre) {
      handleOpenForm();
    }
  }
  useEffect(() => {
    getProveedor();
    // eslint-disable-next-line
  }, [nombre]);

  return (
    <div>
      <Header />
      <div style={{ height: 350, width: '100%' }}>
        {data ? (
          <DataGrid
            rows={data}
            columns={columnas}
            getRowId={(row) => row.ID}
            onRowClick={handleOpenFormParams}
            style={{ height: '90vh' }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  CONTACTO: false,
                  RAZON_SOCIAL: false,
                  WEB: false,
                  CPOSTAL: false,
                  TELEFONO2: false,
                  ID: false,
                  PASSWORD: false,
                },
              },
            }}
          />
        ) : (
          <div className="no-data">
            <MdOutlineError style={{ fontSize: '50px' }} />
            <h1>No hay datos.</h1>
          </div>
        )}
      </div>

      <div className="flex-center">
        <div></div>
        <button className="buttonAltaCliente" onClick={handleOpenForm}>
          Alta nuevo proveedor
        </button>
      </div>
      {openForm && (
        <div className="alta-container">
          <Form
            titulo={'Alta proveedor'}
            msg={'Crear cuenta para un proveedor'}
            tipoUsuario={'Proveedores'}
            rowDataClick={datosRow}
            onCloseForm={handleCloseForm}
            proveedor={nombre && nombre}
          />
        </div>
      )}
    </div>
  );
};
