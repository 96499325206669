import './CardPedidoCarrito.css';

export default function CardPedidoCarrito({
  img,
  nombre,
  cantidad,
  precio,
  precioUnitario,
  idLinea,
  idCliente,
  refresh,
}) {
  function eliminar() {
    let url = `${process.env.REACT_APP_API}/portDelArticuloPedido.php?id_linea=${idLinea}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          refresh();
        }
      });
  }

  return (
    <div className="card-pedido-carrito-container">
      <img src={img} alt="" />
      <div className="data-card-carrito">
        <h3>{nombre}</h3>
        <h3>Precio unitario: {precioUnitario}€</h3>
        <h3>Importe total: {precio}€</h3>
        <h4>Cantidad: {cantidad}</h4>
      </div>
      <button onClick={eliminar}>X</button>
    </div>
  );
}
