import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { useState } from 'react';
// Importaciones modal
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export const TarifasActualizar = () => {
  const navigate = useNavigate();
  const { nombre, porcentage, logistica, id, vencimiento } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  function handleTarifaSubmit(data) {
    data['id'] = id;
    fetch(`${process.env.REACT_APP_API}/portActTarifas.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          navigate('/admin/tarifas');
        }
      });
    reset();
  }

  function borrarTarifa() {
    Swal.fire({
      title: '¿Estás seguro de que quieres borrar la tarifa?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `${process.env.REACT_APP_API}/portBorrar.php?id=${id}&tabla=TARIFAS`
        )
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              navigate('/admin/tarifas');
            }
          });
      }
    });
  }
  const [open, setOpen] = useState(false);
  const [observaciones, setObservaciones] = useState('');

  const handleObservacionesChange = (event) => {
    const nuevoTexto = event.target.value;
    setObservaciones(nuevoTexto);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    window.open(
      `${process.env.REACT_APP_API}/tcpdf_new/examples/listarTarifas.php?tarifa=${nombre}`
    );
  };
  return (
    <div>
      <Header />

      <div className="container-tarifas">
        <h1>Actualizar tarifa</h1>
      </div>
      <div className="creador-tarifas">
        <form onSubmit={handleSubmit(handleTarifaSubmit)}>
          <BsFillTrashFill className="trash-tarifas" onClick={borrarTarifa} />
          <div className="tarifa-block">
            <label htmlFor="nombre_tarifa">Nombre tarifa</label>
            {errors.nombre_tarifa?.type === 'required' && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="text"
              name="nombre_tarifa"
              id="nombre_tarifa"
              defaultValue={nombre}
              {...register('nombre_tarifa', {
                required: true,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="porcentage_tarifa">Porcentage %</label>
            {errors.porcentage_tarifa?.type === 'required' && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="number"
              name="porcentage_tarifa"
              id="porcentage_tarifa"
              defaultValue={porcentage}
              // onChange={() => alert("s")}
              {...register('porcentage_tarifa', {
                required: false,
              })}
            />
          </div>
          <div className="tarifa-block">
            <label htmlFor="logistica">Logistica</label>
            {errors.logistica?.type === 'required' && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="number"
              name="logistica"
              id="logistica"
              defaultValue={logistica}
              // onChange={() => alert("s")}
              {...register('logistica', {
                required: false,
              })}
            />
          </div>
          <div className="tarifa-block">
            <label htmlFor="vencimiento">Vencimiento</label>
            {errors.logistica?.type === 'required' && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="datetime-local"
              name="vencimiento"
              id="vencimiento"
              defaultValue={vencimiento}
              // onChange={() => alert("s")}
              {...register('vencimiento', {
                required: true,
              })}
            />
          </div>
          <div className="tarifa-block">
            <label htmlFor="" style={{ opacity: 0 }}>
              crear
            </label>
            <input type="submit" value="Actualizar tarifa" />
          </div>
        </form>
      </div>

      <div className="volver-btn-container">
        <button
          className="volver-btn"
          onClick={() => navigate('/admin/tarifas')}
        >
          Volver a tarifas
        </button>
        <button
          className="volver-btn"
          onClick={() => {
            handleClickOpen();
          }}
        >
          Ver Tarifa
        </button>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          ¿Desea agregar alguna observación a la tarifa?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Agregue un comentario si así lo desea.
          </DialogContentText>
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Observaciones..."
            multiline
            rows={5}
            value={observaciones}
            onChange={handleObservacionesChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_API}/tcpdf_new/examples/listarTarifas.php?tarifa=${nombre}&comentario=${observaciones}`
              );
              handleClose();
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
