import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { DataGrid } from '@mui/x-data-grid';
import { MdOutlineError } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
// import { useNavigate } from "react-router-dom";
import './GestionPedidos.css';

export const GestionPedidos = () => {
  const [lineas, setLineas] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm();
  //   const navigate = useNavigate();

  const columns = [
    {
      field: 'ID',
      headerName: 'ID',
      width: 90,
      align: 'center',
      renderCell: (params) => '📦00' + params.value,
    },
    {
      field: 'img',
      headerName: 'Imagen',
      width: 200,
      height: 500,
      renderCell: (params) => (
        <img
          style={{ width: '100%', height: '100%' }}
          src={params.value}
          alt=""
        />
      ),
    },
    {
      field: 'PROVEEDOR',
      headerName: 'Proveedor',
      width: 350,
      editable: false,
    },
    {
      field: 'PRODUCTO',
      headerName: 'Producto',
      width: 200,
      editable: false,
    },
    {
      field: 'CLIENTE',
      headerName: 'Cliente',
      width: 300,
      editable: false,
    },
    {
      field: 'UNIDADES',
      headerName: 'Cantidad',
      width: 140,
      editable: false,
      renderCell: (params) => params.value + ' / ' + params.row.UNIDAD_VENTA,
    },
    {
      field: 'DISPONIBILIDAD_INICIAL',
      headerName: 'Stock',
      width: 100,
      height: 500,
    },
    {
      field: 'DESTINO',
      headerName: 'Destino Mercovasa',
      width: 200,
      height: 500,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          <div className="checkbox-wrapper">
            <input
              id="canarias"
              type="checkbox"
              name="canarias"
              defaultChecked={params.row.DESTINO}
              disabled
            />
            <svg viewBox="0 0 35.6 35.6">
              <circle
                r="17.8"
                cy="17.8"
                cx="17.8"
                className="background"
              ></circle>
              <circle r="14.37" cy="17.8" cx="17.8" className="stroke"></circle>
              <polyline
                points="11.78 18.12 15.55 22.23 25.17 12.87"
                className="check"
              ></polyline>
            </svg>
          </div>
        </div>
      ),
    },
  ];

  function getLineas(state) {
    fetch(
      `${process.env.REACT_APP_API}/portGestionPedidos.php?id_estado=${state}`
    )
      .then((response) => response.json())
      .then((response) => {
        setLineas(response.data);
      });
  }
  const handleCloseForm = () => {
    getLineas(stateFilter);

    setOpenForm(false);
  };
  const handleRowClick = (params) => {
    setSelectedRowData(params.row);
    setOpenForm(true);
    if (params.row) {
      setValue('nombre', params.row.ID_PRODUCTO);
      setValue('idProveedor', params.row.ID_PROVEEDOR);
      setValue('cantidad', params.row.UNIDADES);
      setValue('destino', params.row.DESTINO);
    }
  };
  const [dataCombos, setDataCombos] = useState(null);
  function getDataCombos() {
    fetch(`${process.env.REACT_APP_API}/portDesplegables.php`)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);
      });
  }

  useEffect(() => {
    getLineas(stateFilter);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getDataCombos();
  }, []);

  function handleChangeProducto(e) {
    let idProducto = e.target.value;
    let url = `${process.env.REACT_APP_API}/portDesplegables.php?id_producto=${idProducto}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);
      });
  }

  function handleChangeProveedor(e) {
    let idProveedor = e.target.value;
    let url = `${process.env.REACT_APP_API}/portDesplegables.php?id_proveedor=${idProveedor}`;
    fetch(url)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);
      });
  }

  function modificarLineaSubmit(data) {
    const { nombre, idProveedor, cantidad, destino } = data;
    let destinoformat = destino === true ? 1 : 0;
    let idLinea = selectedRowData.ID;

    let url = `${process.env.REACT_APP_API}/portActGestion.php?id_proveedor=${idProveedor}&id_producto=${nombre}&cantidad=${cantidad}&id_linea=${idLinea}&destino=${destinoformat}`;
    fetch(url).then((response) => {
      if (response.ok) {
        Swal.fire({
          text: '¡Modificado exitosamente!',
          type: 'success',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });
        getLineas(stateFilter);
        getDataCombos();
        handleCloseForm();
      } else {
        Swal.fire({
          text: 'Error de modificación',
          type: 'error',
          icon: 'error',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    });
    getLineas(stateFilter);
  }
  const [stateFilter, setStateFilter] = useState(1);
  function handleFilter(e) {
    let term = e.target.value;
    setStateFilter(term);
    getLineas(term);
  }

  return (
    <div>
      <Header />
      <div className="flex-center">
        <select
          name="filter-options"
          id="filter-options"
          onChange={handleFilter}
        >
          <option value="1">Pendiente</option>
          <option value="2">Aceptado</option>
        </select>
        <br />
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
      </div>

      {lineas ? (
        <DataGrid
          rows={lineas && lineas}
          columns={columns}
          getRowId={(row) => row.ID}
          onRowClick={handleRowClick}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
            columns: {
              columnVisibilityModel: {
                ID: false,
              },
            },
          }}
          pageSizeOptions={[5]}
          style={{ height: '90vh' }}
          disableRowSelectionOnClick
        />
      ) : (
        <div className="no-data">
          <MdOutlineError style={{ fontSize: '50px' }} />
          <h1>No hay datos.</h1>
        </div>
      )}

      {/* Formulario Actualizar Linea Pedido */}

      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              boxShadow: 'none',
            },
          },
        }}
      >
        <AiFillCloseCircle
          className="close-dialog-articulo close-mod-line"
          onClick={handleCloseForm}
        />
        <DialogTitle style={{ textAlign: 'center', color: '#370570' }}>
          Modificar linea de pedido
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(modificarLineaSubmit)}>
            <div>
              <div className="form-flex">
                {selectedRowData && (
                  <div>
                    <div className="form-flex">
                      <div className="block-creator">
                        <label htmlFor=""> Proveedor </label>
                        <select
                          style={{
                            width: '300px',
                            textAlign: 'center',
                          }}
                          name="idProveedor"
                          {...register('idProveedor', {
                            required: true,
                          })}
                          onChange={handleChangeProveedor}
                          defaultValue={
                            selectedRowData && selectedRowData.ID_PROVEEDOR
                          }
                        >
                          {dataCombos &&
                            dataCombos['proveedores'].map((dato) => {
                              return (
                                <option value={dato.id} key={dato.id}>
                                  {dato.nombre}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="block-creator">
                        <label htmlFor=""> Producto </label>
                        <select
                          name="nombre"
                          {...register('nombre', {
                            required: true,
                          })}
                          onChange={handleChangeProducto}
                          defaultValue={
                            selectedRowData && selectedRowData.ID_PRODUCTO
                          }
                        >
                          {dataCombos &&
                            dataCombos['producto'].map((dato) => {
                              return (
                                <option value={dato.id} key={dato.id}>
                                  {dato.nombre}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div className="block-creator">
                        <label htmlFor="cantidad">Cantidad</label>
                        <input
                          id="cantidad"
                          required
                          type="number"
                          name="cantidad"
                          defaultValue={
                            selectedRowData && selectedRowData.UNIDADES
                          }
                          {...register('cantidad', {
                            required: true,
                          })}
                        />
                      </div>
                      <div className="block-creator">
                        <label htmlFor="destino">Destino de descarga</label>
                        <div className="checkbox-wrapper">
                          <input
                            id="destino"
                            type="checkbox"
                            name="destino"
                            defaultChecked={
                              selectedRowData && selectedRowData.DESTINO === 1
                                ? true
                                : false
                            }
                            {...register('destino', {
                              required: false,
                            })}
                          />
                          <svg viewBox="0 0 35.6 35.6">
                            <circle
                              r="17.8"
                              cy="17.8"
                              cx="17.8"
                              className="background"
                            ></circle>
                            <circle
                              r="14.37"
                              cy="17.8"
                              cx="17.8"
                              className="stroke"
                            ></circle>
                            <polyline
                              points="11.78 18.12 15.55 22.23 25.17 12.87"
                              className="check"
                            ></polyline>
                          </svg>
                        </div>
                      </div>

                      <input required type="submit" value="Modificar" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
