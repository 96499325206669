import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
// import Divider from "@mui/material/Divider";
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import * as React from 'react';
import { FiMenu, FiUsers } from 'react-icons/fi';
import { HiOutlineBellAlert } from 'react-icons/hi2';
import { AiOutlineDashboard } from 'react-icons/ai';
import { IoIosExit, IoIosListBox } from 'react-icons/io';
import { FaBoxes } from 'react-icons/fa';
import { RxAvatar } from 'react-icons/rx';
import { BsFillBoxSeamFill } from 'react-icons/bs';
import { FaPercentage } from 'react-icons/fa';
import { BiUserPlus } from 'react-icons/bi';
import { IoIosFlash } from 'react-icons/io';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { IoBagAdd } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';

import Logo from '../../../../assets/cerdanLogoUpScale.png';
import './Header.css';
import { Link } from 'react-router-dom';

import { DataContext } from '../../../../context/DataContext';
import { useContext } from 'react';

export const Header = () => {
  const location = useLocation();
  const { userStatus } = useContext(DataContext);
  // eslint-disable-next-line
  const [user, setUser] = userStatus;

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('user');
    return '/';
  };

  const getLink = (text) => {
    if (text === 'Dashboard') {
      return '/admin';
    } else if (text === 'Gestión de usuarios') {
      return '/admin/usuarios';
    } else if (text === 'Gestión de productos') {
      return '/admin/articulos';
    } else if (text === 'Oferta flash') {
      return '/admin/crear-oferta-especial';
    } else if (text === 'Gestión de tarifas') {
      return '/admin/tarifas';
    } else if (text === 'Gestión clientes') {
      return '/admin/gestion-clientes';
    } else if (text === 'Gestión proveedores') {
      return '/admin/gestion-proveedores';
    } else if (text === 'Gestión grupos clientes') {
      return '/admin/gestion-grupos-clientes';
    } else if (text === 'Pedidos') {
      return '/admin/pedidos';
    } else if (text === 'Gestión de pedidos') {
      return '/admin/gestion-pedidos';
    } else if (text === 'Gestión de familias') {
      return '/admin/gestion-familias';
    } else if (text === 'Crear pedido cliente') {
      return '/admin/crear-pedido-cliente';
    } else if (text === 'Cerrar sesión') {
      return handleLogout;
    } else {
      return '/';
    }
  };
  const getPageTitle = () => {
    switch (location.pathname) {
      case '/admin':
        return 'Inicio';
      case '/admin/usuarios':
        return 'Gestión de Usuarios';
      case '/admin/articulos':
        return 'Gestión de Productos';
      case '/admin/crear-oferta-especial':
        return 'Crear Oferta Flash';
      case '/admin/tarifas':
        return 'Gestión de Tarifas';
      case '/admin/gestion-familias':
        return 'Gestión de familias';

      case '/admin/gestion-clientes':
        return 'Gestión de clientes';
      case '/admin/gestion-proveedores':
        return 'Gestión de proveedores';
      case '/admin/gestion-grupos-clientes':
        return 'Gestión Grupos de Clientes';
      case '/admin/pedidos':
        return 'Pedidos';
      case '/admin/gestion-pedidos':
        return 'Gestión de Pedidos';
      case '/admin/crear-pedido-cliente':
        return 'Crear Pedidos para Clientes';

      default:
        return 'Detalles Pedido';
    }
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {[
          'Inicio',
          'Gestión de usuarios',
          'Gestión de productos',
          'Oferta flash',
          'Gestión de tarifas',
          'Gestión de familias',
          'Gestión clientes',
          'Gestión proveedores',
          'Gestión grupos clientes',
          'Pedidos',
          'Gestión de pedidos',
          'Crear pedido cliente',
          'Cerrar sesión',
        ].map((text, index) => (
          <Link
            to={getLink(text)}
            style={{ textDecoration: 'none', color: 'black' }}
            key={index}
            onClick={text === 'Cerrar sesión' ? handleLogout : null}
          >
            <ListItem
              key={text}
              disablePadding
              onClick={text === 'Cerrar sesión' ? handleLogout : null}
            >
              <ListItemButton>
                <ListItemIcon>
                  {index === 0 && (
                    <AiOutlineDashboard
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}
                  {index === 1 && (
                    <FiUsers style={{ color: '#370570', fontSize: '20px' }} />
                  )}
                  {index === 2 && (
                    <BsFillBoxSeamFill
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}
                  {index === 3 && (
                    <IoIosFlash
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}

                  {index === 4 && (
                    <FaPercentage
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}
                  {index === 5 && (
                    <IoIosListBox
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}

                  {index === 6 && (
                    <BiUserPlus
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}
                  {index === 7 && (
                    <BiUserPlus
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}

                  {index === 8 && (
                    <HiOutlineUserGroup
                      style={{ color: '#370570', fontSize: '20px' }}
                    />
                  )}
                  {index === 9 && (
                    <FaBoxes style={{ color: '#370570', fontSize: '20px' }} />
                  )}
                  {index === 10 && (
                    <FaBoxes style={{ color: '#370570', fontSize: '20px' }} />
                  )}
                  {index === 11 && (
                    <IoBagAdd style={{ color: '#370570', fontSize: '20px' }} />
                  )}

                  {index === 12 && (
                    <IoIosExit
                      style={{ color: '#370570', fontSize: '20px' }}
                      onClick={handleLogout}
                    />
                  )}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      {/* <Divider /> */}
    </Box>
  );

  return (
    <div className="container-header-admin">
      <div className="icon-menu" onClick={toggleDrawer('left', true)}>
        <FiMenu />
      </div>
      <div
        className="logo-header"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <span className="migas-pan">{getPageTitle()}</span>
        <img src={Logo} alt="" />
      </div>
      <div className="submenu-profile">
        <HiOutlineBellAlert style={{ visibility: 'hidden' }} />
        <RxAvatar style={{ visibility: 'hidden' }} />
      </div>

      <Drawer
        anchor={'left'}
        open={state['left']}
        onClose={toggleDrawer('left', false)}
      >
        {list('left')}
      </Drawer>
    </div>
  );
};
