import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { DataGrid } from '@mui/x-data-grid';
import { MdOutlineError } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
// Importaciones modal
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import './Pedidos.css';

export const Pedidos = () => {
  const [pedidos, setPedidos] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [open, setOpen] = useState(false);
  const [observaciones, setObservaciones] = useState('');

  const handleObservacionesChange = (event) => {
    const nuevoTexto = event.target.value;
    setObservaciones(nuevoTexto);
  };
  function colorEstado(estado) {
    if (estado === 'Cancelado') {
      return 'estado-cancelado';
    } else if (estado === 'Aceptado') {
      return 'estado-aceptado';
    } else if (estado === 'Pendiente') {
      return 'estado-pendiente';
    } else if (estado === 'Modificado') {
      return 'estado-modificado';
    } else if (estado === 'Finalizado') {
      return 'estado-finalizado';
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function formatFecha(fechaString) {
    // Parsea la cadena de fecha en un objeto Date
    const fecha = new Date(fechaString);

    // Establece los segundos en 0
    fecha.setSeconds(0);

    // Formatea la fecha en el formato deseado
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0');
    const hours = String(fecha.getHours()).padStart(2, '0');
    const minutes = String(fecha.getMinutes()).padStart(2, '0');

    // Crea una cadena de fecha en el formato deseado
    const fechaSinSegundos = `${year}-${month}-${day} ${hours}:${minutes}`;

    return fechaSinSegundos;
  }
  const navigate = useNavigate();

  // function FormatearMonedaEuro(numero) {
  //   // Formatea el número como euros con dos decimales
  //   return numero.toLocaleString("es-ES", {
  //     style: "currency",
  //     currency: "EUR",
  //     minimumFractionDigits: 2,
  //   });
  // }
  const columns = [
    {
      field: 'ID',
      headerName: 'Nº Pedido',
      width: 110,
      align: 'left',
      renderCell: (params) => '📦00' + params.value,
    },
    {
      field: 'NOMBRE_CLIENTE',
      headerName: 'Cliente',
      width: 400,
      editable: true,
    },
    {
      field: 'ESTADO',
      headerName: 'Estado',
      width: 200,
      editable: true,

      renderCell: (params) => (
        <span className={`estado-color ${colorEstado(params.value)}`}>
          {params.value}
        </span>
      ),
    },
    {
      field: 'FECHA',
      headerName: 'Fecha',
      width: 200,
      editable: true,
      renderCell: (params) => formatFecha(params.value['date']),
    },
    {
      field: 'FECHA_ENVIADO',
      headerName: 'Fecha hoja envio',
      width: 200,
      editable: false,
    },
    // {
    //   field: "IMPORTE",
    //   headerName: "Importe",
    //   width: 100,
    //   editable: true,
    //   // renderCell: (params) => params.value + "€",
    //   valueGetter: (params) => {
    //     return FormatearMonedaEuro(params.row.IMPORTE) + "€";
    //   },
    // },
    {
      field: 'asdf',
      headerName: 'Aceptar pedido',
      width: 200,
      height: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <button
          style={{
            background: 'rgb(228 205 255 / 41%)',
            color: '#370570',
            padding: '7px 20px',
            fontWeight: 'bold',
          }}
          onClick={() => navigate(`/admin/pedido-detalles/${params.row.ID}`)}
        >
          Ver detalles
        </button>
      ),
    },
  ];

  function getPedidos(state) {
    fetch(
      `${process.env.REACT_APP_API}/portPedidos.php?id_cliente=0000&id_estado=${state}`
    )
      .then((response) => response.json())
      .then((response) => {
        setPedidos(response.data);
      });
  }
  function handleFilter(e) {
    let term = e.target.value;
    getPedidos(term);
    if (parseInt(term) === 2) {
      setShowButtons(true);
    } else {
      setShowButtons(false);
    }
  }

  function enviarZ() {
    return fetch(`${process.env.REACT_APP_API}/portVerificarPdf.php?doc=4`)
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          // Llamada directa a window.open
          window.open(
            `${process.env.REACT_APP_API}/tcpdf_new/examples/zClientes.php`,
            '_blank'
          );
        } else {
          Swal.fire({
            text: 'Todavía no se puede enviar...',
            type: 'warning',
            icon: 'warning',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }

  function prevHoja() {
    return fetch(`${process.env.REACT_APP_API}/portVerificarPdf.php?doc=2`)
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          // Llamada directa a window.open
          window.open(
            `${process.env.REACT_APP_API}/tcpdf_new/examples/listarPedidosCliente.php`,
            '_blank'
          );
        } else {
          Swal.fire({
            text: 'Todavía no se puede enviar...',
            type: 'warning',
            icon: 'warning',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }

  async function enviarHojaProveedores() {
    try {
      await enviarZ(); // Espera a que enviarZ termine.
      await prevHoja(); // Espera a que prevHoja termine.

      const verificarResponse = await fetch(
        `${process.env.REACT_APP_API}/portVerificarPdf.php?doc=3`
      );
      const verificarData = await verificarResponse.json();

      if (verificarData === 'Ok') {
        const pedidosResponse = await fetch(
          `${process.env.REACT_APP_API}/tcpdf_new/examples/pedidosCliente.php?observaciones=${observaciones}`
        );

        if (pedidosResponse.ok) {
          Swal.fire({
            text: '¡Enviado exitosamente!',
            type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            text: '¡Error al enviar!',
            type: 'error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      } else {
        Swal.fire({
          text: 'Todavía no se puede enviar...',
          type: 'warning',
          icon: 'warning',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Error en enviarHojaProveedores:', error);
      Swal.fire({
        text: 'Ocurrió un error inesperado.',
        type: 'error',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false,
      });
    }
  }

  // function enviarHojaProveedores() {
  //   enviarZ();
  //   prevHoja();
  //   fetch(`${process.env.REACT_APP_API}/portVerificarPdf.php?doc=3`)
  //     .then((data) => data.json())
  //     .then((data) => {
  //       if (data === 'Ok') {
  //         fetch(
  //           `${process.env.REACT_APP_API}/tcpdf_new/examples/pedidosCliente.php?observaciones=${observaciones}`
  //         ).then((response) => {
  //           if (response.ok) {
  //             Swal.fire({
  //               text: '¡Enviado exitosamente!',
  //               type: 'success',
  //               icon: 'success',
  //               timer: 2000,
  //               showConfirmButton: false,
  //             });
  //           } else {
  //             Swal.fire({
  //               text: '¡Error al enviar!',
  //               type: 'success',
  //               icon: 'success',
  //               timer: 2000,
  //               showConfirmButton: false,
  //             });
  //           }
  //         });
  //       } else {
  //         Swal.fire({
  //           text: 'tadavía no se puede enviar...',
  //           type: 'warning',
  //           icon: 'warning',
  //           timer: 2000,
  //           showConfirmButton: false,
  //         });
  //       }
  //     });

  //   // const data = {};
  //   // data["observaciones"] = observaciones;
  //   // fetch(`${process.env.REACT_APP_API}/tcpdf_new/examples/pedidosCliente.php`, {
  //   //   headers: {
  //   //     Accept: "application/json",
  //   //     "Content-Type": "application/json",
  //   //   },
  //   //   method: "POST",
  //   //   body: JSON.stringify(data),
  //   // });
  // }
  function hojaUrcisol() {
    fetch(`${process.env.REACT_APP_API}/portVerificarPdf.php?doc=1`)
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          window.open(
            `${process.env.REACT_APP_API}/tcpdf_new/examples/listarPedidosUrcisol.php`
          );
        } else {
          Swal.fire({
            text: 'tadavía no se puede enviar...',
            type: 'warning',
            icon: 'warning',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }
  useEffect(() => {
    getPedidos(1);
  }, []);

  return (
    <div>
      <Header />
      <div className="flex-center">
        <select
          name="filter-options"
          id="filter-options"
          onChange={handleFilter}
        >
          {/* <option value="" defaultChecked>
              Todos
            </option>
             */}
          <option value="1">Pendiente</option>
          <option value="2">Aceptado</option>
          <option value="3">Modificado</option>
          <option value="4">Cancelado</option>
          <option value="5">Finalizado</option>
        </select>

        <br />
        <div>
          <br />
        </div>
        <div></div>
        <div>
          <br />
        </div>
      </div>
      {pedidos ? (
        <DataGrid
          rows={pedidos && pedidos}
          columns={columns}
          getRowId={(row) => row.ID}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 30,
              },
            },
          }}
          pageSizeOptions={[30]}
          style={{ height: '90vh' }}
          disableRowSelectionOnClick
        />
      ) : (
        <div className="no-data">
          <MdOutlineError style={{ fontSize: '50px' }} />
          <h1>No hay datos.</h1>
        </div>
      )}
      {showButtons && (
        <button onClick={hojaUrcisol} id="hoja-urcisol">
          Plantilla URCISOL
        </button>
      )}

      {showButtons && (
        <div className="buttons-hojas">
          <button onClick={prevHoja}>Previsualizar hoja</button>
          <button onClick={handleClickOpen}>
            Enviar hoja de trabajo a proveedores
          </button>
          <button onClick={enviarZ}>Emitir Z de trabajo</button>
        </div>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Hoja de trabajo proveedores</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Envío de hoja de trabajo para proveedores, cumplimente el campo de
            observaciones si fuese necesario.
          </DialogContentText>
          <br />
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Observaciones..."
            multiline
            rows={5}
            value={observaciones}
            onChange={handleObservacionesChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            onClick={() => {
              handleClose();
              enviarHojaProveedores();
            }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
