import { HeaderProveedor } from '../../../../components/Proveedor/HeaderProveedor/HeaderProveedor';
import { BsFillSkipBackwardFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import './MiCuentaProveedor.css';

export const MiCuentaProveedor = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    // formState: { errors },
  } = useForm();
  let user = window.localStorage.getItem('user');
  user = JSON.parse(user);
  const ID_PROVEEDOR = user[0].ID;

  function actualizarPassword(data) {
    data['idProveedor'] = ID_PROVEEDOR;
    fetch(`${process.env.REACT_APP_API}/portCambioPass.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          reset();
          Swal.fire({
            text: '¡Contraseña actualizada exitosamente!',
            type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            text: 'Error al Actualizar',
            type: 'error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }
  return (
    <div className="container-mi-cuenta-proveedor">
      <HeaderProveedor />

      <div className="container-form-proveedor-cuenta">
        <BsFillSkipBackwardFill
          className="volver-proveedor"
          onClick={() => navigate('/proveedor')}
        />

        <div className="header-mi-cuenta-proveedor">
          <h1>Mi cuenta</h1>
        </div>

        <div className="form-micuenta-proveedor">
          <form
            className="form-proveedor"
            onSubmit={handleSubmit(actualizarPassword)}
          >
            <p className="form-title-proveedor">Cambiar contraseña</p>
            <div className="input-container-proveedor">
              <input
                type="password"
                placeholder="Contraseña actual"
                {...register('oldPassword', {
                  required: true,
                })}
              />
              <span></span>
            </div>
            <div className="input-container-proveedor">
              <input
                type="password"
                placeholder="Nueva contraseña"
                {...register('newPassword', {
                  required: true,
                })}
              />
            </div>
            <button type="submit" className="submit-proveedor">
              Enviar
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
