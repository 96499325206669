import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { BsFillTrashFill } from 'react-icons/bs';
import Swal from 'sweetalert2';
import { useEffect, useState } from 'react';

export const GestionGrupoClientesActualizar = () => {
  const navigate = useNavigate();
  const { nombre, nombreTarifa, id } = useParams();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  function handleTarifaSubmit(data) {
    data['id'] = id;
    fetch(`${process.env.REACT_APP_API}/portActGruposClientes.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          navigate('/admin/gestion-grupos-clientes');
        }
      });
    reset();
  }

  function borrarGrupo() {
    Swal.fire({
      title: '¿Estás seguro de que quieres borrar el grupo?',
      showDenyButton: true,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `${process.env.REACT_APP_API}/portBorrar.php?id=${id}&tabla=GRUPOS_CLIENTES`
        )
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              navigate('/admin/gestion-grupos-clientes');
            }
          });
      }
    });
  }

  const [tarifas, setTarifas] = useState([]);
  function getTarifas() {
    fetch(`${process.env.REACT_APP_API}/portTarifas.php`)
      .then((data) => data.json())
      .then((data) => {
        setTarifas(data.data);

        setValue('id_tarifa', parseInt(nombreTarifa && nombreTarifa));
      });
  }

  useEffect(() => {
    getTarifas();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />

      <div className="container-tarifas">
        <h1>Actualizar grupo de clientes</h1>
      </div>
      <div className="creador-tarifas">
        <form onSubmit={handleSubmit(handleTarifaSubmit)}>
          <BsFillTrashFill className="trash-tarifas" onClick={borrarGrupo} />
          <div className="tarifa-block">
            <label htmlFor="nombre">Nombre grupo</label>
            {errors.nombre?.type === 'required' && (
              <p className="error">Por favor, cumplimente el campo.</p>
            )}
            <input
              type="text"
              name="nombre"
              id="nombre_tarifa"
              defaultValue={nombre}
              {...register('nombre', {
                required: true,
              })}
            />
          </div>

          <div className="tarifa-block">
            <label htmlFor="id_tarifa">Tarifas</label>
            <select
              name="id_tarifa"
              {...register('id_tarifa', {
                required: true,
              })}
            >
              {tarifas &&
                tarifas.map((dato, index) => {
                  return (
                    <option value={dato.ID} key={index}>
                      {dato.NOMBRE}
                    </option>
                  );
                })}
            </select>
          </div>

          <div className="tarifa-block">
            <label htmlFor="" style={{ opacity: 0 }}>
              crear
            </label>
            <input type="submit" value="Actualizar grupo" />
          </div>
        </form>
      </div>

      <div className="volver-btn-container">
        <button
          className="volver-btn"
          onClick={() => navigate('/admin/gestion-grupos-clientes')}
        >
          Volver
        </button>
      </div>
    </div>
  );
};
