import { useNavigate } from 'react-router-dom';
import Logo404 from '../../assets/LogoColor2.png';
import './Error404.css';

export const Error404 = () => {
  const navigate = useNavigate();
  return (
    <div id="main404">
      <div className="logo404">
        <img src={Logo404} alt="" />
      </div>
      <div className="fof">
        <h1>Error 404</h1>
      </div>
      <div className="button404">
        <button onClick={() => navigate('/')}>Volver</button>
      </div>
    </div>
  );
};
