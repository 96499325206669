const PUBLIC_VAPID_KEY =
  'BAj6cZdcBpqo8HiEGub95SodPRnG_tZB9F1aQyk5E1_IWVF2HdPfNUsxanqkI5PiUiOWesk2In8-PoO5iY-H-xE';

export default async function serviceWorker() {
  if ('serviceWorker' in navigator) {
    try {
      // Registra el Service Worker
      const registration = await navigator.serviceWorker.register('./sw.js');
      console.log('Service Worker registrado con éxito:', registration);

      // Obtiene la suscripción actual, si existe
      const subscription = await registration.pushManager.getSubscription();
      let userId = localStorage.getItem('user');
      userId = JSON.parse(userId);
      userId = userId[0].ID;

      if (subscription) {
        // Si ya hay una suscripción, desuscríbete
        await subscription.unsubscribe();
        console.log('Desuscripción exitosa');
      }

      // Realiza una nueva suscripción
      const newSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: PUBLIC_VAPID_KEY,
      });
      const subscriptionData = {
        subscription: newSubscription,
        userId: userId,
      };

      console.log(subscriptionData);
      // Envía la nueva suscripción al servidor
      await fetch(`${process.env.REACT_APP_NODE_API}/subscription`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(subscriptionData),
      });
    } catch (error) {
      console.error('Error en el Service Worker:', error);
    }
  } else {
    console.log('Los Service Workers no son compatibles en este navegador.');
  }
}
