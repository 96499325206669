import { Link } from 'react-router-dom';
import { MdHome, MdSearch, MdShoppingCart, MdPerson } from 'react-icons/md';
import { IoExit } from 'react-icons/io5';
import { DataContext } from '../../../context/DataContext';
import { useContext, useEffect } from 'react';
import Logo from '../../../assets/cerdanLogoUpScale.png';
import './Menu.css';
import Swal from 'sweetalert2';

export const Menu = ({ ruta }) => {
  const { userStatus } = useContext(DataContext);
  // eslint-disable-next-line
  const [user, setUser] = userStatus;

  const { counterCarrito } = useContext(DataContext);

  const [cartCounter, setCartCounter] = counterCarrito;

  const handleLogout = () => {
    if (cartCounter && cartCounter > 0) {
      Swal.fire({
        title:
          'No has finalizado tu pedido, ¿Seguro que quieres cerrar sesión?',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Cerrar Sesión',
        denyButtonText: 'Cancelar',
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setUser(null);
          localStorage.removeItem('user');
          return '/';
        }
      });
    } else {
      setUser(null);
      localStorage.removeItem('user');
      return '/';
    }
  };

  function getDataCounterCart() {
    let userId = localStorage.getItem('user');
    userId = JSON.parse(userId);
    userId = userId[0].ID;
    fetch(`${process.env.REACT_APP_API}/portCarrito.php?id_cliente=${userId}`)
      .then((response) => response.json())
      .then((response) => {
        setCartCounter(response.data?.length);
        if (response.data === null) {
          localStorage.removeItem('id_pedido');
        }
      });
  }

  useEffect(
    () => {
      getDataCounterCart();
    }, // eslint-disable-next-line
    []
  );

  return (
    <div className="header" id="header">
      <nav className="navbar container">
        <Link to="/" className="brand">
          <img src={Logo} alt="" style={{ width: '150px' }} />
        </Link>
        <div className="menu" id="menu">
          <ul className="menu-list">
            <li className="menu-item">
              <Link
                to="/"
                className={
                  ruta === 'cliente' ? 'menu-link is-active' : 'menu-link'
                }
              >
                <MdHome className="menu-icon" />
                <span className="menu-name">Inicio</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/cliente/pedidos"
                className={
                  ruta === 'pedidos' ? 'menu-link is-active' : 'menu-link'
                }
              >
                <MdSearch className="menu-icon" />
                <span className="menu-name">Mis Pedidos</span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/cliente/pedido"
                className={
                  ruta === 'carrito' ? 'menu-link is-active' : 'menu-link'
                }
                id="relative-ct"
              >
                <MdShoppingCart className="menu-icon" />
                {cartCounter && cartCounter > 0 && (
                  <span className="counter-cart">{cartCounter}</span>
                )}
                <span className="menu-name">
                  <span>Carrito </span>
                </span>
              </Link>
            </li>
            <li className="menu-item">
              <Link
                to="/cliente/mi-cuenta"
                className={
                  ruta === 'mi-cuenta' ? 'menu-link is-active' : 'menu-link'
                }
              >
                <MdPerson className="menu-icon" />
                <span className="menu-name">Mi cuenta</span>
              </Link>
            </li>
            <li
              className="menu-item"
              onClick={handleLogout}
              style={{ cursor: 'pointer' }}
            >
              <IoExit className="menu-icon" />
              <span className="menu-name">Salir</span>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};
