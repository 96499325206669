import { Header } from '../../../../components/Auth/Admin/Header/Header';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import './DetallesPedido.css';

export const DetallesPedido = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [cabecera, setCabecera] = useState(null);

  const navigate = useNavigate();

  // function FormatearMonedaEuro(numero) {
  //   // Formatea el número como euros con dos decimales
  //   return numero.toLocaleString("es-ES", {
  //     style: "currency",
  //     currency: "EUR",
  //     minimumFractionDigits: 2,
  //   });
  // }
  const columns = [
    { field: 'ID', headerName: 'ID', width: 90, height: 500 },
    {
      field: 'img',
      headerName: 'Imagen Producto',
      width: 200,
      height: 500,
      renderCell: (params) => (
        <img
          style={{ width: '100%', height: '100%' }}
          src={params.value}
          alt=""
        />
      ),
    },

    { field: 'ARTICULO', headerName: 'Nombre', width: 300, height: 500 },
    { field: 'VARIEDAD', headerName: 'Categoria', width: 120, height: 500 },
    { field: 'CALIBRE', headerName: 'Calibre', width: 120, height: 500 },

    {
      field: 'CANTIDAD',
      headerName: 'Cantidad',
      width: 100,
      height: 500,
      renderCell: (params) => params.value + '/' + params.row.UNIDAD_VENTA,
    },
    {
      field: 'IMPORTE_UNITARIO',
      headerName: 'Precio unitario',
      width: 160,
      height: 500,
      renderCell: (params) => params.value + '€',
    },
    // {
    //   field: "IMPORTE_TOTAL",
    //   headerName: "Importe total",
    //   width: 160,
    //   height: 500,
    //   renderCell: (params) => params.value + "€",
    // },
  ];

  function getDatosPedido() {
    fetch(
      `${process.env.REACT_APP_API}/portDatosPedido.php?id_cliente=0000&id_pedido=${id}`
    )
      .then((response) => response.json())
      .then((response) => {
        setData(response.data);
      });
  }

  function handleCancelarPedido() {
    Swal.fire({
      title: '¿Seguro que quieres cancelar este pedido?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `${process.env.REACT_APP_API}/portCambioEstadoPedido.php?id_pedido=${id}&id_estado=4`
        )
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              Swal.fire({
                text: 'Pedido cancelado exitosamente!',
                type: 'success',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
              navigate(-1);
            }
          });
      }
    });
  }

  function handleAceptarPedido() {
    Swal.fire({
      title: '¿Seguro que quieres aceptar este pedido?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Si',
      denyButtonText: 'No',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        fetch(
          `${process.env.REACT_APP_API}/portCambioEstadoPedido.php?id_pedido=${id}&id_estado=2`
        )
          .then((data) => data.json())
          .then((data) => {
            if (data === 'Ok') {
              Swal.fire({
                text: 'Pedido aceptado exitosamente!',
                type: 'success',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              });
              navigate(-1);
            }
          });
      }
    });
  }
  function getDatosCabecera() {
    fetch(`${process.env.REACT_APP_API}/portCabeceraPedido.php?id_pedido=${id}`)
      .then((response) => response.json())
      .then((response) => {
        setCabecera(response.data);
      });
  }
  function formatFecha(fechaString) {
    // Parsea la cadena de fecha en un objeto Date
    const fecha = new Date(fechaString);

    // Establece los segundos en 0
    fecha.setSeconds(0);

    // Formatea la fecha en el formato deseado
    const year = fecha.getFullYear();
    const month = String(fecha.getMonth() + 1).padStart(2, '0');
    const day = String(fecha.getDate()).padStart(2, '0');
    const hours = String(fecha.getHours()).padStart(2, '0');
    const minutes = String(fecha.getMinutes()).padStart(2, '0');

    // Crea una cadena de fecha en el formato deseado
    const fechaSinSegundos = `${year}-${month}-${day} ${hours}:${minutes}`;

    return fechaSinSegundos;
  }
  useEffect(() => {
    getDatosPedido();
    getDatosCabecera();
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header />
      <div className="container-pedidos-detalle">
        <div className="detalles-pedido">
          <h2>Detalles del pedido</h2>
          <ul>
            <li>
              <span>Número de pedido:</span> #00{id}
            </li>
            <li>
              <span>Nombre cliente: </span>{' '}
              {cabecera && cabecera[0].RAZON_SOCIAL}
            </li>
            <li>
              <span>Nombre comercial: </span>{' '}
              {cabecera && cabecera[0].NOMBRE_COMERCIAL}
            </li>
            <li>
              <span>Dirección de entrega:</span>{' '}
              {cabecera && cabecera[0].DIRECCION}
            </li>
            <li>
              <span>Código postal:</span> {cabecera && cabecera[0].CPOSTAL}
            </li>
            <li>
              <span>Fecha de pedido:</span>{' '}
              {formatFecha(cabecera && cabecera[0].FECHA['date'])}
            </li>
            {/* <li>
              <span>Subtotal: </span> 320,89€
            </li> */}
            {/* <li>
              <span>IVA:</span> 34,95€
            </li> */}
            <li>
              <span>Total:</span> {cabecera && cabecera[0].IMPORTE}€
            </li>
          </ul>
          <button className="aceptarPedidoButton" onClick={handleAceptarPedido}>
            📦 Aceptar pedido #{id} 📦
          </button>
          <button
            className="cancelarPedidoButton"
            onClick={handleCancelarPedido}
          >
            📦 Cancelar pedido #{id} 📦
          </button>

          <button
            className="aceptarPedidoButton volverPedidosButton"
            onClick={() => navigate(-1)}
          >
            Volver a pedidos
          </button>
        </div>
        <div className="grid-detalles-pedido">
          <DataGrid
            rows={data && data}
            columns={columns}
            getRowId={(row) => row.ID}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 7,
                },
              },
              columns: {
                columnVisibilityModel: {
                  ID: false,
                  REFERENCIA_CLIENTE: false,
                  ID_GRUPO: false,
                },
              },
            }}
            pageSizeOptions={[7]}
            disableRowSelectionOnClick
          />
        </div>
      </div>
    </div>
  );
};
