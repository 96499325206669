import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { RiCloseCircleLine } from 'react-icons/ri';
import { AiFillCloseCircle } from 'react-icons/ai';
import { MdOutlineError } from 'react-icons/md';
import { useState, useEffect, useContext } from 'react';
import { DataContext } from '../../../context/DataContext';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import { useForm } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';
import Swal from 'sweetalert2';
// import { useNavigate } from "react-router-dom";
import './DataTableProveedor.css';

export const DataTableProveedor = ({
  datos,
  columnas,
  alturaCelda,
  form,
  altura,
  contenedor,
}) => {
  // eslint-disable-next-line
  const [selectedRow, setSelectedRow] = React.useState(null);
  // eslint-disable-next-line
  const [openForm, setOpenForm] = React.useState(false);
  const [selectedRowData, setSelectedRowData] = React.useState(null);
  const [imagenes, setImagenes] = React.useState([]);

  // const navigate = useNavigate();
  const handleRowClick = (params) => {
    setSelectedRow(params.id);
    setSelectedRowData(params.row);
    setOpenForm(true);

    if (params.row) {
      setValue('precioOrigen', params.row.PRECIO_ORIGEN);
      setValue('idUnidadesVenta', params.row.ID_UNIDADES_VENTA);
      setValue('disponibilidadInicial', params.row.DISPONIBILIDAD_INICIAL);

      setValue('idFormato', params.row.ID_FORMATO);
      setValue('refCliente', params.row.REFERENCIA_CLIENTE);
      setValue('idBultos', params.row.ID_BULTOS);
      setValue('marca', params.row.MARCA);
    }

    fetch(
      `${process.env.REACT_APP_API}/portArticulosImagenes.php?id=${params.row.ID}`
    )
      .then((data) => data.json())
      .then((data) => setImagenes(data));
  };
  const [dataCombos, setDataCombos] = useState(null);
  function getDataCombos() {
    fetch(`${process.env.REACT_APP_API}/portDesplegables.php`)
      .then((data) => data.json())
      .then((data) => {
        setDataCombos(data.data);
      });
  }

  useEffect(() => {
    getDataCombos();
  }, []);
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handleImagenChange = (event) => {
    const files = event.target.files;
    const imagenesArray = [];

    for (let i = 0; i < files.length; i++) {
      const reader = new FileReader();
      reader.onload = (e) => {
        imagenesArray.push(e.target.result);
        if (imagenesArray.length === files.length) {
          setImagenes((prevImagenes) => [...prevImagenes, ...imagenesArray]);
        }
      };
      reader.readAsDataURL(files[i]);
    }
  };
  const eliminarImagen = (index) => {
    setImagenes((prevImagenes) => prevImagenes.filter((_, i) => i !== index));
  };

  const {
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm();
  const { estadoArticulos } = useContext(DataContext);
  // eslint-disable-next-line
  const { estadoArticuloIdContextData } = useContext(DataContext);

  const [updateArticulos, setUpdateArticulos] = estadoArticulos;
  function actualizarProducto(data) {
    data['id'] = selectedRowData.ID;
    data['imagenes'] = imagenes;
    if (typeof data.precioOrigen == 'string') {
      let precioFormat2 = data.precioOrigen
        .replace(/[.\s€]/g, '')
        .replace(',', '.');
      data['precioOrigen'] = parseFloat(precioFormat2);
    }

    let price = parseInt(data.precioOrigen);
    if (price > 0) {
      fetch(
        `${process.env.REACT_APP_API}/portActEstadoArticulo.php?id=${data.id}&estado=2`
      );
    }
    fetch(`${process.env.REACT_APP_API}/portActArticuloProveedor.php`, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((data) => data.json())
      .then((data) => {
        if (data === 'Ok') {
          setUpdateArticulos(updateArticulos === false ? true : false);
          handleCloseForm();
          Swal.fire({
            text: '¡Producto actualizado!',
            type: 'success',
            icon: 'success',
            timer: 2000,
            showConfirmButton: false,
          });
        } else {
          Swal.fire({
            text: '¡Erro al actualizar!',
            type: 'error',
            icon: 'error',
            timer: 2000,
            showConfirmButton: false,
          });
        }
      });
  }

  return (
    <div
      style={
        !contenedor
          ? { height: 400, width: '100%' }
          : { height: contenedor, width: '100%' }
      }
    >
      {datos ? (
        <DataGrid
          rows={datos}
          columns={columnas}
          onRowClick={form && handleRowClick}
          getRowId={(row) => row.ID}
          style={!altura ? { height: '90vh' } : { height: altura }}
          rowHeight={alturaCelda}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ID: false,
                ID_GRUPO: false,
                ID_UNIDADES_VENTA: false,
              },
            },
          }}
        />
      ) : (
        <div className="no-data">
          <MdOutlineError style={{ fontSize: '50px' }} />
          <h1>No hay datos.</h1>
        </div>
      )}
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              boxShadow: 'none',
            },
          },
        }}
      >
        <AiFillCloseCircle
          className="close-dialog-articulo"
          onClick={() => setOpenForm(false)}
        />
        <DialogTitle style={{ textAlign: 'center', color: '#370570' }}>
          Datos del producto
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(actualizarProducto)}>
            {form && selectedRowData && (
              <div>
                <div className="form-flex">
                  <div className="block-creator">
                    <label htmlFor="nombre"> Nombre </label>
                    <input
                      type="text"
                      name="nombre"
                      defaultValue={selectedRowData.NOMBRE}
                      disabled
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="codigo"> Codigo </label>
                    <input
                      type="text"
                      name="codigo"
                      defaultValue={selectedRowData.CODIGO}
                      disabled
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="variedad"> Variedad </label>
                    <input
                      type="text"
                      name="variedad"
                      defaultValue={selectedRowData.VARIEDAD}
                      disabled
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="calibre"> Calibre </label>
                    <input
                      type="text"
                      name="calibre"
                      defaultValue={selectedRowData.CALIBRE}
                      disabled
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="marca"> Marca </label>
                    <input
                      type="text"
                      name="marca"
                      defaultValue={selectedRowData.MARCA}
                      {...register('marca', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="block-creator">
                    <label htmlFor="refCliente"> Referencia proveedor </label>
                    <input
                      type="text"
                      name="refCliente"
                      defaultValue={selectedRowData.REFERENCIA_CLIENTE}
                      {...register('refCliente', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="idFormato"> Tara </label>

                    <select
                      name="idFormato"
                      {...register('idFormato', {
                        required: true,
                      })}
                    >
                      {dataCombos &&
                        dataCombos['formato'].map((dato) => {
                          return (
                            <option value={dato.id} key={dato.id}>
                              {dato.nombre}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="block-creator">
                    <label htmlFor="precio-origen">
                      {' '}
                      Precio de referencia{' '}
                    </label>

                    <CurrencyInput
                      id="input-example"
                      name="precio-origen"
                      decimalsLimit={2}
                      defaultValue={selectedRowData.PRECIO_ORIGEN}
                      decimalScale={2}
                      intlConfig={{
                        locale: 'de-DE',
                        currency: 'EUR',
                      }}
                      {...register('precioOrigen', {
                        required: true,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor="unidad-venta"> Unidad de venta </label>

                    <select
                      name="idUnidadesVenta"
                      {...register('idUnidadesVenta', {
                        required: true,
                      })}
                    >
                      {dataCombos &&
                        dataCombos['unidades'].map((dato) => {
                          return (
                            <option value={dato.id} key={dato.id}>
                              {dato.nombre}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="block-creator">
                    <label htmlFor="idBultos"> Nº de bultos x palet </label>
                    <input
                      type="number"
                      name="idBultos"
                      defaultValue={selectedRowData.ID_BULTOS}
                      {...register('idBultos', {
                        required: false,
                      })}
                    />
                  </div>
                  <div className="block-creator">
                    <label htmlFor=""> Disponibilidad en bultos </label>
                    <input
                      type="number"
                      name="disponibilidadInicial"
                      id="disponibilidadInicial"
                      defaultValue={selectedRowData.DISPONIBILIDAD_INICIAL}
                      {...register('disponibilidadInicial', {
                        required: false,
                      })}
                    />
                  </div>

                  <div className="content-images">
                    <label className="custum-file-upload" htmlFor="file">
                      <div className="icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill=""
                          viewBox="0 0 24 24"
                        >
                          <g strokeWidth="0" id="SVGRepo_bgCarrier"></g>
                          <g
                            strokeLinejoin="round"
                            strokeLinecap="round"
                            id="SVGRepo_tracerCarrier"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {' '}
                            <path
                              fill=""
                              d="M10 1C9.73478 1 9.48043 1.10536 9.29289 1.29289L3.29289 7.29289C3.10536 7.48043 3 7.73478 3 8V20C3 21.6569 4.34315 23 6 23H7C7.55228 23 8 22.5523 8 22C8 21.4477 7.55228 21 7 21H6C5.44772 21 5 20.5523 5 20V9H10C10.5523 9 11 8.55228 11 8V3H18C18.5523 3 19 3.44772 19 4V9C19 9.55228 19.4477 10 20 10C20.5523 10 21 9.55228 21 9V4C21 2.34315 19.6569 1 18 1H10ZM9 7H6.41421L9 4.41421V7ZM14 15.5C14 14.1193 15.1193 13 16.5 13C17.8807 13 19 14.1193 19 15.5V16V17H20C21.1046 17 22 17.8954 22 19C22 20.1046 21.1046 21 20 21H13C11.8954 21 11 20.1046 11 19C11 17.8954 11.8954 17 13 17H14V16V15.5ZM16.5 11C14.142 11 12.2076 12.8136 12.0156 15.122C10.2825 15.5606 9 17.1305 9 19C9 21.2091 10.7909 23 13 23H20C22.2091 23 24 21.2091 24 19C24 17.1305 22.7175 15.5606 20.9844 15.122C20.7924 12.8136 18.858 11 16.5 11Z"
                              clipRule="evenodd"
                              fillRule="evenodd"
                            ></path>{' '}
                          </g>
                        </svg>
                      </div>
                      <div className="text">
                        <span>Click para cargar imagen</span>
                      </div>

                      <input
                        type="file"
                        id="file"
                        name="file"
                        onChange={handleImagenChange}
                        multiple
                      />
                    </label>
                  </div>
                  {imagenes && imagenes.length > 0 ? (
                    <div className="galeria-imagenes">
                      {imagenes.map((imagen, index) => (
                        <div className="imagen-container" key={index}>
                          <img src={imagen} alt={`Imagen ${index}`} />
                          <div
                            className="eliminar-icon"
                            onClick={() => eliminarImagen(index)}
                          >
                            <RiCloseCircleLine />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}

                  <input required type="submit" value="Actualizar producto" />
                </div>
              </div>
            )}
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};
